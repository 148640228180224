import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IVrmProps, vrms } from "../vrm/vrm";
import { sparkleButtonSignal } from "./BottomBar/BottomBar";
import { webCamWasRunningSignal } from "../webcam/useWebCam";
import { useRecordingContext } from "../pages/RecordMode";

/**
 * Renders a window for selecting a VRM (Virtual Reality Model) from a list.
 *
 * @param selectedVrm - The currently selected VRM.
 * @param setSelectedVrm - A function to set the selected VRM.
 * @param selectVrmButtonState - A state variable and setter for the select VRM button.
 * @returns The SelectVRMWindow component.
 */
function SelectVRMWindow({
  selectedVrm,
  setSelectedVrm,
  selectVrmButtonState,
}: {
  selectedVrm?: IVrmProps;
  setSelectedVrm?: React.Dispatch<React.SetStateAction<IVrmProps>>;
  selectVrmButtonState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
}) {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const windowRef = useRef<HTMLDivElement>(null);

  const updatePosition = () => {
    if (sparkleButtonSignal.value && windowRef.current) {
      const rect = sparkleButtonSignal.value.getBoundingClientRect();
      const windowHeight = windowRef.current.offsetHeight;

      setPosition({
        top: rect.top - windowHeight + window.scrollY - 20,
        left: rect.left + window.scrollX,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = sparkleButtonSignal.subscribe(updatePosition);

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectVrmButtonState && selectVrmButtonState[0]) {
      updatePosition();
    }
  }, [selectVrmButtonState]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
    exit: { opacity: 0 },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const { webCamRunning, toggleWebCam } = useRecordingContext();

  const handleSelect = (vrm: IVrmProps) => {
    webCamWasRunningSignal.value = webCamRunning;

    if (webCamRunning) {
      toggleWebCam();
    }

    if (setSelectedVrm) setSelectedVrm(vrm);

    if (selectVrmButtonState) selectVrmButtonState[1](false);
  };

  return (
    <>
      {selectVrmButtonState && (
        <AnimatePresence mode="wait">
          {selectVrmButtonState[0] && (
            <motion.div
              ref={windowRef}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={containerVariants}
              className={`absolute z-50 flex flex-col gap-2 backdrop-blur-lg p-2 rounded-lg text-white pointer-events-auto`}
              style={{ top: position.top, left: position.left }}
              id="select-vrm-window"
            >
              {vrms.map((vrm) => (
                <motion.div
                  key={vrm.name}
                  variants={itemVariants}
                  className={`${
                    selectedVrm === vrm ? "border border-primarygreen" : ""
                  } cursor-pointer  rounded-full px-4 py-1`}
                  onClick={() => handleSelect(vrm)}
                >
                  {vrm.name}
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export { SelectVRMWindow };
