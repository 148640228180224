import { useState, useRef } from "react";
import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import { useDialog } from "../ui-components/Dialog/DialogContextProvider";

/**
 * Custom hook for managing canvas recording functionality.
 *
 * @returns An object containing various functions and state variables related to canvas recording.
 */
function useCanvasRecorder() {
  const [isCanvasRecording, setIsCanvasRecording] = useState(false);
  const canvasRecorderRef = useRef<any>(null);
  const [recordingUrl, setRecordingUrl] = useState<string | null>(null);

  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const audioRecorderRef = useRef<any>(null);
  const audioBlobRef = useRef<Blob | null>(null);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioPlaybackRef = useRef<HTMLAudioElement | null>(null);

  const { showDialog, hideDialog } = useDialog();

  /**
   * Starts the audio recording by requesting microphone access from the user.
   * If the user grants permission, it initializes the audio recorder and starts recording.
   * If the user denies permission, it displays an error dialog.
   */
  const startAudioRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      audioRecorderRef.current = new RecordRTC(audioStream, {
        type: "audio",
        mimeType: "audio/wav",
        recorderType: MediaStreamRecorder,
      });

      audioRecorderRef.current.startRecording();
      setIsAudioRecording(true);
    } catch (error) {
      showDialog({
        title: "Microphone permission denied",
        type: "info",
        content: `User denied microphone access. Please reload the page and grant this web app permission to use your microphone.`,
      });

      console.error(error);
    }
  };

  /**
   * Stops the audio recording and saves the recorded audio blob.
   * Sets the isAudioRecording state to false.
   */
  const stopAudioRecording = () => {
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stopRecording(() => {
        audioBlobRef.current = audioRecorderRef.current.getBlob();
        setIsAudioRecording(false);
      });
    }
  };

  const playAudioRecording = () => {
    if (audioBlobRef.current) {
      const audioUrl = URL.createObjectURL(audioBlobRef.current);
      audioPlaybackRef.current = new Audio(audioUrl);
      audioPlaybackRef.current.play();
      setIsAudioPlaying(true);

      // Event listener to reset state when playback finishes
      audioPlaybackRef.current.addEventListener("ended", () => {
        setIsAudioPlaying(false);
      });
    }
  };

  const stopAudioPlayback = () => {
    if (audioPlaybackRef.current) {
      audioPlaybackRef.current.pause();
      audioPlaybackRef.current.currentTime = 0;
      setIsAudioPlaying(false);
    }
  };

  /**
   * Starts recording the canvas.
   *
   * @param canvas - The HTMLCanvasElement to be recorded.
   */
  const startCanvasRecording = async (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;

    const canvasStream = canvas.captureStream(30);
    let combinedStream = new MediaStream([...canvasStream.getVideoTracks()]);

    if (audioBlobRef.current) {
      const audioContext = new AudioContext();
      const audioSource = audioContext.createBufferSource();
      const audioBlob = audioBlobRef.current;
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      audioSource.buffer = audioBuffer;

      const destination = audioContext.createMediaStreamDestination();
      audioSource.connect(destination);
      audioSource.start(0);

      combinedStream.addTrack(destination.stream.getAudioTracks()[0]);
      // audioBlobRef.current = null;
    }

    canvasRecorderRef.current = new RecordRTC(combinedStream, {
      type: "video",
      mimeType: "video/webm",
      recorderType: MediaStreamRecorder,
      bitsPerSecond: 8000000,
    });

    canvasRecorderRef.current.startRecording();
    setIsCanvasRecording(true);

    showDialog({
      title: "Your video is being created",
      type: "info",
      content: `Please sit back while we prepare your video.`,
      showCloseButton: false,
    });
  };

  /**
   * Stops the canvas recording and performs necessary actions after stopping.
   */
  const stopCanvasRecording = () => {
    if (canvasRecorderRef.current) {
      canvasRecorderRef.current.stopRecording(() => {
        const blob = canvasRecorderRef.current.getBlob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url ?? "";
        a.download = `Alias-Recording-${new Date().toLocaleString()}.webm`;
        a.click();

        setRecordingUrl(url);
        setIsCanvasRecording(false);
      });
    }

    hideDialog();
  };

  return {
    isCanvasRecording,
    isAudioRecording,
    startAudioRecording,
    stopAudioRecording,
    startCanvasRecording,
    stopCanvasRecording,
    isAudioPlaying,
    playAudioRecording,
    stopAudioPlayback,
    recordingUrl,
  };
}

export { useCanvasRecorder };
