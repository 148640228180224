// Motion Capture Core - Core Manager

import {
  FaceLandmarkerOptions,
  FaceLandmarkerResult,
  ImageSource,
  NormalizedLandmark,
} from "@mediapipe/tasks-vision";
import { getBodyPosition } from "./body-position";
import { getBoneRotate } from "./bone-rotate";
import {
  checkFaceLandmarker,
  initFaceLandmarker,
  predictFaceLandmark,
} from "./face-landmark";
import { recordResult } from "./record";
import {
  IEmbeddingResult,
  IUnifiedFaceBlendshape,
} from "../types/motion-capture";

/**
 * Default configuration options for the face landmarker.
 */
let defaultLMConfig: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: "model/face_landmarker.task",
    delegate: "GPU",
  },
  outputFaceBlendshapes: true,
  runningMode: "VIDEO",
  numFaces: 1,
};

async function initMoCapCore() {
  initFaceLandmarker(defaultLMConfig);
}

function checkMoCapCore() {
  try {
    let check = true;
    check &&= checkFaceLandmarker();
    return check;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Transforms a FaceLandmarkerResult into an IEmbeddingResult.
 * @param faceLandmarkerResult The input FaceLandmarkerResult object.
 * @returns The transformed IEmbeddingResult object.
 */
function transformToEmbeddingResult(
  faceLandmarkerResult: FaceLandmarkerResult
): IEmbeddingResult {
  return {
    faceLandmarks: faceLandmarkerResult.faceLandmarks as NormalizedLandmark[][],
    faceBlendshapes:
      faceLandmarkerResult.faceBlendshapes as IUnifiedFaceBlendshape[],
    facialTransformationMatrixes:
      faceLandmarkerResult.facialTransformationMatrixes,
    boneRotates: getBoneRotate(faceLandmarkerResult),
    bodyPositions: getBodyPosition(faceLandmarkerResult),
    time: performance.now(),
  };
}

/**
 * Runs the motion capture core algorithm on the provided image data.
 * @param data The image data to process.
 * @returns The result of the motion capture algorithm, or undefined if the algorithm fails.
 */
function runMoCapCore(data: ImageSource): IEmbeddingResult | undefined {
  let faceLandmarkerResult = predictFaceLandmark(data);
  let embeddingResult = transformToEmbeddingResult(faceLandmarkerResult);
  let embResult = recordResult(embeddingResult);

  return embResult;
}

export { initMoCapCore, checkMoCapCore, runMoCapCore };
