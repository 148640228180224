import { useNavigate, useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect } from "react";
import { BsRecordCircle, BsTrashFill } from "react-icons/bs";
import { useRecordingContext } from "../../pages/RecordMode";
import { RecordButton } from "./RecordButton";
import {
  BsCameraVideoFill,
  BsCameraVideoOffFill,
  BsDownload,
} from "react-icons/bs";
import { SparklesButton } from "../../ui-components/SparklesButton";
import { signal } from "@preact/signals-react";
import { IVrmProps } from "../../vrm/vrm";

export const cameraButtonSignal = signal<HTMLDivElement | null>(null);
export const sparkleButtonSignal = signal<HTMLDivElement | null>(null);
export const recordButtonSignal = signal<HTMLDivElement | null>(null);
export const bottomBarSignal = signal<HTMLDivElement | null>(null);

/**
 * Renders the bottom bar component.
 *
 * @param settingsState - The state and setter for the settings.
 * @param bgWindowState - The state and setter for the background window.
 * @param vrmWindowState - The state and setter for the VRM window.
 * @param selectedVrm - The selected VRM props.
 * @param setSelectedVrm - The setter for the selected VRM props.
 * @param recordingUrl - The URL of the recording.
 * @returns The rendered bottom bar component.
 */
export function BottomBar({
  settingsState,
  bgWindowState,
  vrmWindowState,
  selectedVrm,
  setSelectedVrm,
  recordingUrl,
}: {
  settingsState?: [boolean, Dispatch<SetStateAction<boolean>>];
  bgWindowState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  vrmWindowState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  selectedVrm?: IVrmProps;
  setSelectedVrm?: React.Dispatch<React.SetStateAction<IVrmProps>>;
  recordingUrl?: string | null;
}) {
  const navigate = useNavigate();
  const { recordingState, setRecordingState } = useRecordingContext();
  const { toggleWebCam, webCamRunning } = useRecordingContext();
  const location = useLocation();

  useEffect(() => {}, [location]);

  const handleDownloadClick = () => {
    setRecordingState("recording-canvas");
  };

  return (
    <div
      className={`w-[100vw] h-full top-0 fixed flex flex-col justify-end items-center pb-8 pointer-events-none gap-4`}
    >
      <div
        className={`h-auto py-2 px-8 flex justify-center pointer-events-auto items-center gap-10 ${
          location.pathname === "/" ? "" : "hidden"
        }`}
      >
        <div className={`h-5 invisible`}>
          <BsRecordCircle className={`text-white w-auto h-full object-cover`} />
        </div>
        {recordingState === "stopped" && (
          <>
            <div
              className={`h-5 cursor-pointer`}
              onClick={() => {
                setRecordingState("initializing");
              }}
            >
              <BsTrashFill
                className={`text-white w-auto h-full object-cover hover:text-primarygreen`}
              />
            </div>
            <div className={`h-5 w-5`}>
              <BsDownload
                className={`text-white  w-full h-auto cursor-pointer hover:text-primarygreen transition-colors duration-300`}
                onClick={handleDownloadClick}
              />
            </div>
          </>
        )}

        <div className={`h-5 invisible`}>
          <BsRecordCircle className={`text-white w-auto h-full object-cover`} />
        </div>
        {/* <RecordButton /> */}
      </div>
      <div
        className={`w-auto max-w-full h-14 py-2 px-8 bg-black/25 backdrop-blur-md shadow-md backdrop-contrast-125 backdrop-brightness-150 rounded-full flex justify-center pointer-events-auto items-center gap-12`}
        ref={(element) => (bottomBarSignal.value = element)}
      >
        <SparklesButton
          className={`${location.pathname === "/" ? "" : "hidden"}`}
          classNameButton={`w-14 h-auto inline-flex justify-center`}
          bgWindowState={bgWindowState}
          vrmWindowState={vrmWindowState}
          ref={(ref) => (sparkleButtonSignal.value = ref)}
        />

        <div
          onClick={() => navigate("/")}
          className={`w-14 h-full inline-flex justify-center`}
        >
          <RecordButton ref={(ref) => (recordButtonSignal.value = ref)} />

          {/* <BsCameraVideo
            className={`h-full w-auto ${
              location.pathname === "/" ? "text-primarygreen" : "text-white"
            } hover:text-primarygreen cursor-pointer transition-colors duration-300`}
          /> */}
        </div>
        <div
          className={`w-8 h-8 inline-flex justify-center cursor-pointer ${
            location.pathname === "/" ? "" : "hidden"
          }`}
          ref={(element) => (cameraButtonSignal.value = element)}
          onClick={toggleWebCam}
        >
          {webCamRunning ? (
            <BsCameraVideoFill className={`text-primarygreen w-auto h-full `} />
          ) : (
            <BsCameraVideoOffFill
              className={`text-white w-auto h-full hover:text-primarygreen transition-colors duration-300`}
            />
          )}
        </div>
      </div>
    </div>
  );
}
