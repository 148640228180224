import React, { createContext, useContext, useState, useCallback } from "react";
import { DialogComponent, DialogProps } from "./DialogComponent";

// Define the context shape
const DialogContext = createContext({
  showDialog: (props: DialogProps) => {},
  hideDialog: () => {},
});

// Define the provider component
export const DialogContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dialogProps, setDialogProps] = useState<DialogProps>();

  const showDialog = useCallback((props: DialogProps) => {
    setDialogProps({ ...props, isOpen: true });
  }, []);

  const hideDialog = useCallback(() => {
    setDialogProps((prevProps) =>
      prevProps
        ? { ...prevProps, isOpen: false }
        : { isOpen: false, title: "", content: "" }
    );
  }, []);

  const onClose = useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog }}>
      {children}
      {dialogProps && (
        <DialogComponent
          title={dialogProps.title}
          type={dialogProps.type}
          content={dialogProps.content}
          isOpen={dialogProps.isOpen}
          showCloseButton={dialogProps.showCloseButton}
          onClose={onClose}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
