import { AnimatePresence, motion } from "framer-motion";
import { loadingStateSignal } from "../../vrm/VrmComponent";

export function LoadingIndicator() {
  return (
    <AnimatePresence>
      {loadingStateSignal.value.isLoading && (
        <motion.div
          id={`loading-indicator`}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
          className={`fixed top-0 font-purista text-xl text-white w-[100vw] h-[100vh] gap-4 flex flex-col justify-center items-center pointer-events-none`}
        >
          <div className={`animate-pulse`}>Loading</div>
          <div className="animate-spin rounded-full w-24 aspect-square border-b-2 border-primarygreen"></div>
          <div className={`animate-pulse`}>
            {loadingStateSignal.value.progress.toFixed(2)} %
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
