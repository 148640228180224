import { useContext } from "react";
import { ButtonStateContext } from "../dom/ContextBar/ButtonElement";

export default function IconSparkles() {
  const state = useContext(ButtonStateContext);
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8323 11.413C13.829 11.6778 13.6586 11.9218 13.3652 12.0844C12.8652 12.3614 12.363 12.635 11.8596 12.9054C10.4936 13.6384 9.45071 14.6934 8.721 16.0594C8.45834 16.5517 8.188 17.0397 7.91985 17.5287C7.7572 17.8243 7.51104 17.9958 7.16816 17.998C6.82418 17.9991 6.57801 17.8276 6.41427 17.532C6.07579 16.9199 5.75599 16.2968 5.40102 15.6945C4.66472 14.4439 3.62839 13.5054 2.3459 12.8317C1.86895 12.5812 1.3942 12.3229 0.928234 12.0515C0.482054 11.791 0.365564 11.2525 0.668878 10.8459C0.757894 10.7261 0.894166 10.6305 1.02604 10.5569C1.61289 10.2294 2.21622 9.93156 2.79538 9.59198C4.0394 8.86337 4.97572 7.83693 5.64609 6.56323C5.90325 6.07419 6.1659 5.58625 6.43735 5.10491C6.7033 4.63565 7.28685 4.52685 7.69676 4.86643C7.79237 4.94556 7.8693 5.05655 7.93084 5.16645C8.28581 5.80055 8.6111 6.45334 8.98695 7.07425C9.70127 8.25234 10.6947 9.14031 11.9113 9.7854C12.4003 10.0448 12.8839 10.3129 13.3685 10.581C13.6806 10.7536 13.8334 11.0085 13.8334 11.4152L13.8323 11.413ZM7.17146 15.433C8.10448 13.6164 9.4606 12.2691 11.2673 11.3294C9.4617 10.3975 8.10668 9.0414 7.16596 7.2314C6.22635 9.05019 4.86693 10.3997 3.06352 11.3349C4.87792 12.2713 6.23404 13.6208 7.17255 15.433H7.17146Z"
        className={`${state ? "fill-primarygreen" : "fill-white"}`}
      />
      <path
        d="M19.9733 11.3272C19.77 11.3272 19.5271 11.1789 19.3667 10.8987C19.1579 10.5338 18.9634 10.1601 18.7469 9.80078C18.0391 8.62489 17.0753 7.71605 15.8698 7.06107C15.6368 6.93468 15.4049 6.80611 15.1708 6.68082C14.8598 6.51488 14.6719 6.26981 14.6763 5.90495C14.6807 5.54669 14.8675 5.30712 15.1752 5.14557C15.7786 4.82797 16.3808 4.51037 16.9215 4.08946C17.8248 3.38613 18.5205 2.51355 19.048 1.5014C19.1447 1.31458 19.248 1.13215 19.3524 0.948622C19.5139 0.666188 19.7535 0.500244 20.0865 0.500244C20.4239 0.500244 20.6656 0.665089 20.8316 0.954117C21.1195 1.45305 21.3844 1.96736 21.7053 2.44321C22.4031 3.47514 23.3339 4.25321 24.4362 4.83127C24.634 4.93567 24.8285 5.04776 25.0241 5.15656C25.3242 5.3225 25.5 5.56647 25.5 5.91704C25.5 6.26871 25.3165 6.50829 25.0198 6.67533C24.6021 6.91161 24.1757 7.1347 23.7713 7.39185C22.6152 8.12486 21.7525 9.12712 21.1294 10.3404C21.0316 10.5316 20.9305 10.7217 20.8206 10.9052C20.6591 11.1756 20.4206 11.3261 19.9744 11.3261L19.9733 11.3272ZM20.0876 8.80293C20.4711 8.20729 20.8876 7.67759 21.3701 7.20063C21.8514 6.72368 22.3734 6.29619 22.9746 5.91924C21.7932 5.16645 20.8393 4.21035 20.0832 3.03006C19.3304 4.22024 18.3732 5.16645 17.2039 5.92144C18.3897 6.67533 19.3304 7.62154 20.0865 8.80293H20.0876Z"
        className={`${state ? "fill-primarygreen" : "fill-white"}`}
      />
      <path
        d="M16.2665 25.4984C16.0269 25.494 15.784 25.3522 15.6225 25.0786C15.4302 24.7511 15.2587 24.4115 15.0543 24.0928C14.2763 22.8774 13.2333 21.9531 11.9366 21.319C11.7497 21.2278 11.575 21.1212 11.4618 20.9377C11.2069 20.5212 11.353 19.9926 11.7827 19.7574C12.1509 19.5552 12.53 19.3684 12.8806 19.1387C13.9642 18.4299 14.7906 17.4837 15.3917 16.3385C15.4664 16.1968 15.5368 16.0528 15.6214 15.9176C15.9643 15.3648 16.7259 15.3714 17.0632 15.9275C17.338 16.3803 17.5842 16.8539 17.8864 17.2869C18.626 18.3452 19.6227 19.1068 20.7624 19.6948C20.9272 19.7805 21.0942 19.9036 21.2008 20.053C21.4965 20.464 21.3305 20.997 20.8668 21.2652C20.4008 21.5355 19.9249 21.796 19.4876 22.1081C18.4798 22.8257 17.7446 23.7818 17.1841 24.8786C17.1446 24.9555 17.1028 25.0324 17.0567 25.1061C16.8995 25.3566 16.639 25.4984 16.2643 25.4984H16.2665ZM18.8502 20.4992C17.8402 19.8244 17.0215 18.987 16.3357 17.9958C15.6489 18.9991 14.829 19.8266 13.8268 20.4992C14.8247 21.1751 15.6544 22.007 16.3379 23.0092C17.0248 22.0015 17.8512 21.1762 18.8502 20.4992Z"
        className={`${state ? "fill-primarygreen" : "fill-white"}`}
      />
    </svg>
  );
}
