import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

export type DialogContent = string | React.ReactNode;

export type DialogType = "info" | "warning" | "error";

export interface DialogProps {
  title: string;
  type?: DialogType;
  content: DialogContent;
  showCloseButton?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

const DialogComponent: React.FC<DialogProps> = ({
  title,
  type = "info",
  content,
  showCloseButton = true,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(externalIsOpen ?? false);
  const actualIsOpen = externalOnClose ? externalIsOpen : internalIsOpen;

  const closeModal = () => {
    setInternalIsOpen(false);
    if (externalOnClose) {
      externalOnClose();
    }
  };

  useEffect(() => {
    if (externalOnClose) {
      setInternalIsOpen(externalIsOpen ?? false);
    }
  }, [externalIsOpen, externalOnClose]);

  function getTitleColor() {
    switch (type) {
      case "info":
        return "text-primarygreen";
      case "warning":
        return "text-[#ff0037]";
      case "error":
        return "text-[#ff0037]";
      default:
        return "text-primarygreen";
    }
  }

  useEffect(() => {
    // console.log("Dialog showing:", actualIsOpen);
  }, [actualIsOpen]);

  return (
    <>
      {/** Tailwind includes */}
      <div className="invisible hidden">
        <div className="text-primarygreen"></div>
        <div className="text-[#ff0307]"></div>
        <div className="bg-[#ff0037]"></div>
      </div>
      <Transition appear show={actualIsOpen} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-10 selection:bg-primarygreen selection:text-charcoal ${
            type === "warning" ? "border border-red-400" : ""
          }`}
          onClose={closeModal}
          open={actualIsOpen}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md flex flex-col gap-4 transform overflow-hidden rounded-2xl bg-white/5 backdrop-blur-lg p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-medium leading-6 ${getTitleColor()}`}
                  >
                    {title}
                  </Dialog.Title>
                  <div className="">
                    {typeof content === "string" ? (
                      <p className="text-sm text-justify text-gray-300">
                        {content}
                      </p>
                    ) : (
                      content
                    )}
                  </div>

                  <div className="self-center">
                    {showCloseButton && (
                      <button
                        type="button"
                        className={`inline-flex justify-center rounded-md border border-transparent bg-charcoal px-4 py-2 text-sm font-medium text-gray-300 ${
                          type === "warning"
                            ? "hover:bg-[#ff0037]/70"
                            : "hover:bg-primarygreen/70 hover:text-black"
                        }   transition-colors focus:outline-none`}
                        onClick={closeModal}
                      >
                        Got it, thanks!
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { DialogComponent };
