import { forwardRef } from "react";

type DropdownItemProps = {
  hover: boolean;
  label?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
};

const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
  (
    { label, hover, icon, children, onClick, ...props }: DropdownItemProps,
    ref: any
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={`${
          hover ? "bg-gray-300/20 text-primarygreen" : "text-white"
        } group flex gap-2 flex-row w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
        onClick={onClick && onClick}
      >
        {icon && icon}
        {label && <div>{label}</div>}
        {children && children}
      </div>
    );
  }
);

export { DropdownItem, type DropdownItemProps };
