import { createContext, useState } from "react";
import { Button } from "./Button";
import { AnimatePresence } from "framer-motion";
import React from "react";

const MountStateContext = createContext<boolean | undefined>(undefined);

function UISection({
  children,
  description,
  className,
  classNameContainer,
  title,
  mountButtonEnabled = true,
  backgroundImage,
}: {
  children?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  classNameContainer?: string;
  title?: string;
  mountButtonEnabled?: boolean;
  backgroundImage?: string;
}) {
  const [mountState, setMountState] = useState(true);

  const handleMountButton = () => {
    setMountState(!mountState);
  };

  return (
    <MountStateContext.Provider value={mountState}>
      <section className={`flex flex-col gap-4 relative ${className}`}>
        <div className={`text-white text-2xl`}>{title}</div>

        {mountButtonEnabled && (
          <Button
            label={`${mountState ? "Unmount" : "Mount"}`}
            onClick={handleMountButton}
          ></Button>
        )}

        {description && description}

        <div
          className={`relative w-auto h-auto flex flex-col items-center ${classNameContainer}`}
          style={{
            backgroundImage: `${
              backgroundImage ? `url(${backgroundImage})` : ""
            }`,
            backgroundSize: "cover",
          }}
        >
          <AnimatePresence mode="wait">
            {mountState && (
              <React.Fragment key={`content-${title}`}>
                {children && children}
              </React.Fragment>
            )}
          </AnimatePresence>
        </div>
      </section>
    </MountStateContext.Provider>
  );
}

export { UISection, MountStateContext };
