import { useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";
import { availableBackgrounds } from "../background/Background";

/**
 * Renders a canvas with a background image.
 * @param {Object} props - The component props.
 * @param {string} props.imageUrl - The URL of the background image.
 * @returns {null} - Returns null.
 */
function BackgroundImageCanvas({ imageUrl }: { imageUrl: string }) {
  const { scene, gl } = useThree();

  imageUrl = imageUrl ? imageUrl : availableBackgrounds["Static Image"][0].url;

  const texture = useTexture(imageUrl);
  texture.minFilter = THREE.LinearFilter;

  useEffect(() => {
    if (!texture.image) return;

    const canvasAspect = gl.domElement.clientWidth / gl.domElement.clientHeight;
    const imageAspect = texture.image
      ? texture.image.width / texture.image.height
      : 1;
    const aspect = imageAspect / canvasAspect;

    texture.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0;
    texture.repeat.x = aspect > 1 ? 1 / aspect : 1;

    texture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
    texture.repeat.y = aspect > 1 ? 1 : aspect;

    scene.background = texture;
    scene.backgroundIntensity = 0.333;

    return () => {
      scene.background = null;
    };
  }, [gl.domElement.clientHeight, gl.domElement.clientWidth, scene, texture]);

  return null;
}

export default BackgroundImageCanvas;
