import { useContext } from "react";
import { ButtonStateContext } from "../dom/ContextBar/ButtonElement";

export default function IconImage() {
  const state = useContext(ButtonStateContext);

  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 6.99996C15.6667 5.89539 16.5621 4.99996 17.6667 4.99996C18.7712 4.99996 19.6667 5.89539 19.6667 6.99996C19.6667 8.10453 18.7712 8.99996 17.6667 8.99996C16.5621 8.99996 15.6667 8.10453 15.6667 6.99996Z"
        className={`${state ? "fill-primarygreen" : "fill-white"}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69004 1.21136C10.8888 0.846375 15.1112 0.846375 19.31 1.21136L21.3236 1.3864C23.2504 1.55389 24.8113 3.02009 25.0989 4.93261C25.7037 8.95489 25.7037 13.045 25.0989 17.0673C24.8113 18.9798 23.2504 20.446 21.3236 20.6135L19.31 20.7885C15.1113 21.1535 10.8888 21.1535 6.69004 20.7885L4.6764 20.6135C2.74964 20.446 1.18875 18.9798 0.901151 17.0673C0.296296 13.045 0.296296 8.95489 0.901151 4.93261C1.18875 3.02009 2.74964 1.55389 4.6764 1.38641L6.69004 1.21136ZM19.1368 3.20385C15.0533 2.84888 10.9467 2.84888 6.86325 3.20385L4.8496 3.37889C3.84383 3.46632 3.02904 4.23168 2.87891 5.23002C2.53553 7.51351 2.39714 9.81998 2.46374 12.122L7.62622 6.95952C7.82097 6.76477 8.08741 6.65897 8.3627 6.66706C8.63799 6.67515 8.89776 6.79642 9.08074 7.00226L13.976 12.5094L17.3504 11.3846C17.7278 11.2588 18.1438 11.3687 18.41 11.6643L23.1021 16.8778C23.1093 16.8422 23.1157 16.8062 23.1211 16.7699C23.6963 12.9448 23.6963 9.05514 23.1211 5.23002C22.971 4.23168 22.1562 3.46632 21.1504 3.37889L19.1368 3.20385ZM21.8269 18.4506L17.3615 13.4891L13.9829 14.6153C13.6034 14.7418 13.185 14.63 12.9192 14.331L8.29051 9.12366L2.63226 14.7819C2.6973 15.446 2.77952 16.1089 2.87891 16.7699C3.02904 17.7682 3.84383 18.5336 4.8496 18.621L6.86325 18.7961C10.9467 19.151 15.0533 19.151 19.1368 18.7961L21.1504 18.621C21.3887 18.6003 21.6163 18.5415 21.8269 18.4506Z"
        className={`${state ? "fill-primarygreen" : "fill-white"}`}
      />
    </svg>
  );
}
