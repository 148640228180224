/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Motion Capture Core - Capture Manager
// Wei-1 2023-10-09

import { NormalizedLandmark } from "@mediapipe/tasks-vision";
import { RecordState } from "../pages/RecordMode";
import {
  IUnifiedFaceBlendshape,
  IEmbeddingResult,
  IBodyPosition,
  IBoneRotate,
  IRecordConfig,
} from "../types/motion-capture";
import { checkMoCapCore } from "./core";
import { useState, useRef, useEffect } from "react";
import { signal } from "@preact/signals-react";

let lastCheckTime = performance.now();
const checkInterval = 1000; // 1000 milliseconds (1 second) as an example interval
const recordingStartTimeSignal = signal(performance.now());
const playBackStartTimeSignal = signal(performance.now());

let recording: IEmbeddingResult[] = [];
let defaultRecordConfig: IRecordConfig = {
  maxDuration: 300,
  framePerSecond: 30,
};

/**
 * Custom hook for recording and playing back motion capture data.
 *
 * @param renderCallback - The callback function to render each frame of the recording.
 * @param recordState - The state of the recording, consisting of a tuple with the current record state and a function to update the record state.
 * @returns An object containing functions to start recording, stop recording, view the recorded motion, and stop the playback loop, as well as the captured recording.
 */
function useRecording({
  renderCallback,
  recordState,
}: {
  renderCallback: (results: IEmbeddingResult) => void;
  recordState:
    | [RecordState, React.Dispatch<React.SetStateAction<RecordState>>]
    | null;
}) {
  const [capturedRecording, setCapturedRecording] = useState<
    IEmbeddingResult[] | null
  >(null);

  useEffect(() => {
    console.log(capturedRecording);
  }, [capturedRecording]);

  const recordingIndex = useRef(0);
  const playbackLoopRef = useRef<number | null>(null);

  const [record, setRecordState] = recordState
    ? recordState
    : ["initializing", () => {}];

  /**
   * Starts the recording process.
   * @param config - Optional configuration for the recording.
   */
  function startRecording({ config }: { config?: IRecordConfig } = {}) {
    if (!checkMoCapCore()) {
      console.log("Wait! ML model not loaded yet.");
      return;
    }
    recordingStartTimeSignal.value = performance.now();

    clearRecording();
    setRecordConfig(config ? config : defaultRecordConfig);
  }

  function stopRecording() {
    if (!checkMoCapCore()) {
      console.log("Wait! ML model not loaded yet.");
      return;
    }

    setCapturedRecording(interpolateRecording());
  }

  /**
   * Stops the playback loop.
   */
  function stopPlaybackLoop() {
    if (playbackLoopRef.current) {
      cancelAnimationFrame(playbackLoopRef.current);
      playbackLoopRef.current = null;

      setRecordState("stopped");
    }
  }

  function viewRecording() {
    if (playbackLoopRef.current) {
      stopPlaybackLoop();
    } else {
      playBackStartTimeSignal.value = performance.now();
      recordingIndex.current = 0;
      playbackLoopRef.current = requestAnimationFrame(playbackLoop);
    }
  }

  /**
   * Executes a loop to playback a captured recording.
   */
  function playbackLoop() {
    try {
      const currentTime = performance.now();
      const elapsedTime = currentTime - playBackStartTimeSignal.value;

      while (
        capturedRecording &&
        recordingIndex.current < capturedRecording.length
      ) {
        let currentFrame = capturedRecording[recordingIndex.current];
        let nextFrameTime =
          recordingIndex.current + 1 < capturedRecording.length
            ? capturedRecording[recordingIndex.current + 1].time
            : null;

        if (elapsedTime >= currentFrame.time) {
          if (nextFrameTime && elapsedTime >= nextFrameTime) {
            // Handle skipped frames: Jump to the frame that matches the current time
            while (
              nextFrameTime &&
              elapsedTime >= nextFrameTime &&
              recordingIndex.current < capturedRecording.length
            ) {
              recordingIndex.current++;
              nextFrameTime =
                recordingIndex.current + 1 < capturedRecording.length
                  ? capturedRecording[recordingIndex.current + 1].time
                  : null;
            }
            // Update currentFrame after skipping
            currentFrame = capturedRecording[recordingIndex.current];
          }

          // Render the current frame
          renderCallback(currentFrame);
          recordingIndex.current++;
        } else {
          // Break the loop if the current frame's time hasn't been reached yet
          break;
        }
      }

      // Schedule the next iteration or stop if finished
      if (
        capturedRecording &&
        recordingIndex.current < capturedRecording.length
      ) {
        playbackLoopRef.current = requestAnimationFrame(playbackLoop);
      } else {
        stopPlaybackLoop(); // End of playback
      }
    } catch (error) {
      console.error("Playback loop error:", error);
      stopPlaybackLoop();
    }
  }

  /**
   * Interpolates between two motion capture records based on a given time.
   * @param record1 - The first motion capture record.
   * @param record2 - The second motion capture record.
   * @param time - The time at which to interpolate the records.
   * @returns The interpolated motion capture record.
   */
  function interRecord(
    record1: IEmbeddingResult,
    record2: IEmbeddingResult,
    time: number
  ): IEmbeddingResult {
    let time1 = record1.time;
    let time2 = record2.time;
    let w1 = Math.max(0, time2 - time);
    let w2 = Math.max(0, time - time1);

    return {
      time: time,
      faceBlendshapes: [
        interpolateFaceBlendshape(
          record1.faceBlendshapes[0] as IUnifiedFaceBlendshape,
          record2.faceBlendshapes[0] as IUnifiedFaceBlendshape,
          w1,
          w2
        ),
      ],
      boneRotates: [
        interpolateBoneRotates(
          record1.boneRotates[0],
          record2.boneRotates[0],
          w1,
          w2
        ),
      ],
      bodyPositions: [
        interpolateBodyPosition(
          record1.bodyPositions[0],
          record2.bodyPositions[0],
          w1,
          w2
        ),
      ],
      faceLandmarks: [],
      facialTransformationMatrixes: [],
    };
  }

  /**
   * Interpolates the recording data to fill in missing frames based on the specified frame rate.
   * @returns An array of interpolated recording data.
   */
  function interpolateRecording(): IEmbeddingResult[] {
    try {
      let recordLength = recording.length;
      console.log(
        `Recorded with length of: ${recordLength} - ${(
          recordLength / defaultRecordConfig.framePerSecond
        ).toFixed(2)}s`
      );
      let curRecord = recording[0];
      let curTime = curRecord.time;
      let duration = 1000.0 / defaultRecordConfig.framePerSecond;
      let finalRecording = [];
      for (let i = 1; i < recordLength; i++) {
        let nextRecord = recording[i];
        while (curTime <= nextRecord.time) {
          let newRecord = interRecord(curRecord, nextRecord, curTime);
          finalRecording.push(newRecord);
          curTime += duration;
        }
        curRecord = nextRecord;
      }
      return finalRecording;
    } catch (e) {
      console.log("Error interpolating recording: ", e);
      return [];
    }
  }

  function clearRecording() {
    recording = [];
  }

  useEffect(() => {
    return () => {
      stopPlaybackLoop(); // Ensure the loop is stopped when the component is unmounted
    };
  }, []);

  return {
    startRecording,
    stopRecording,
    capturedRecording,
    viewRecording,
    stopPlaybackLoop,
  };
}

/**
 * Sets the record configuration.
 * @param config - The record configuration object.
 */
function setRecordConfig(config: IRecordConfig) {
  if (config) {
    Object.keys(config).forEach(function (key) {
      //@ts-ignore
      defaultRecordConfig[key] = config[key];
    });
  }
}

/**
 * Records the result of an embedding operation.
 * @param result The embedding result to be recorded.
 * @returns The recorded embedding result.
 */
function recordResult(result: IEmbeddingResult) {
  try {
    let nowTime = performance.now();
    const relativeTime = nowTime - recordingStartTimeSignal.value;

    let embResult = embedding(result, relativeTime);

    if (embResult.faceBlendshapes.length > 0) {
      recording.push(embResult);
    }
    // Perform the duration check at fixed intervals
    if (recording[0] && nowTime - lastCheckTime > checkInterval) {
      while (
        nowTime - recording[0].time >
        defaultRecordConfig.maxDuration * 1000
      ) {
        recording.shift();
      }
      lastCheckTime = nowTime;
    }
    return embResult;
  } catch (e) {
    console.error("Error recording result: ", e);
  }
}

/**
 * Packs an array of blend shapes into a single unified blend shape.
 * @param blendShapes - The array of blend shapes to pack.
 * @returns The packed blend shape as an array.
 */
function packBlendShape(
  blendShapes: IUnifiedFaceBlendshape[]
): IUnifiedFaceBlendshape[] {
  if (blendShapes.length > 0) {
    let result: IUnifiedFaceBlendshape = {
      categories: [],
      headIndex: 0,
      headName: "",
    };

    // Replace map with forEach
    blendShapes[0].categories.forEach((shape: any) => {
      let key = shape.displayName || shape.categoryName;
      result[key] = shape.score;
    });
    return [result];
  }
  return [];
}

/**
 * Embeds the given result and time into an embedding result object.
 * @param result - The result to embed.
 * @param time - The time value to include in the embedding result.
 * @returns The embedded result object.
 */
function embedding(result: IEmbeddingResult, time: number): IEmbeddingResult {
  let embResult: IEmbeddingResult = {
    time: time,
    faceBlendshapes: packBlendShape(result.faceBlendshapes),
    boneRotates: result.boneRotates,
    bodyPositions: result.bodyPositions,
    facialTransformationMatrixes: result.facialTransformationMatrixes,
    faceLandmarks: result.faceLandmarks,
  };

  return embResult;
}

function linearInter(v1: number, v2: number, w1: number, w2: number) {
  return (v1 * w1 + v2 * w2) / (w1 + w2);
}

/**
 * Interpolates between two face blendshapes based on the given weights.
 * @param o1 - The first face blendshape object.
 * @param o2 - The second face blendshape object.
 * @param w1 - The weight for the first face blendshape.
 * @param w2 - The weight for the second face blendshape.
 * @returns The interpolated face blendshape object.
 */
function interpolateFaceBlendshape(
  o1: IUnifiedFaceBlendshape,
  o2: IUnifiedFaceBlendshape,
  w1: number,
  w2: number
): IUnifiedFaceBlendshape {
  // Clone o1 to get an initial structure that includes both static and dynamic properties
  let interpolated: IUnifiedFaceBlendshape = JSON.parse(JSON.stringify(o1));

  // Update the dynamic properties by interpolating between o1 and o2
  Object.keys(o1).forEach((key) => {
    if (!["categories", "headIndex", "headName"].includes(key)) {
      interpolated[key] = linearInter(o1[key], o2[key], w1, w2);
    }
  });

  // Optionally, handle categories if needed
  // interpolated.categories = ...; // Implement logic if necessary

  return interpolated;
}

/**
 * Interpolates between two body positions based on given weights.
 * @param a1 - The first body position.
 * @param a2 - The second body position.
 * @param w1 - The weight for the first body position.
 * @param w2 - The weight for the second body position.
 * @returns The interpolated body position.
 */
function interpolateBodyPosition(
  a1: IBodyPosition,
  a2: IBodyPosition,
  w1: number,
  w2: number
): IBodyPosition {
  // Ensure that the result is a tuple of three numbers
  const result: NormalizedLandmark = {
    x: linearInter(a1.position.x, a2.position.x, w1, w2),
    y: linearInter(a1.position.y, a2.position.y, w1, w2),
    z: linearInter(a1.position.z, a2.position.z, w1, w2),
  };

  return { position: result };
}

/**
 * Interpolates between two bone rotations based on given weights.
 * @param o1 - The first bone rotation object.
 * @param o2 - The second bone rotation object.
 * @param w1 - The weight for the first bone rotation.
 * @param w2 - The weight for the second bone rotation.
 * @returns The interpolated bone rotation object.
 */
function interpolateBoneRotates(
  o1: IBoneRotate,
  o2: IBoneRotate,
  w1: number,
  w2: number
): IBoneRotate {
  let o: IBoneRotate = {
    head: [0, 0, 0],
    neck: [0, 0, 0],
    spine: [0, 0, 0],
  };

  o.head = interArray(o1.head, o2.head, w1, w2);
  o.neck = interArray(o1.neck, o2.neck, w1, w2);
  o.spine = interArray(o1.spine, o2.spine, w1, w2);
  return o;
}

/**
 * Interpolates between two arrays using linear interpolation.
 * @param a1 The first array.
 * @param a2 The second array.
 * @param w1 The weight for the first array.
 * @param w2 The weight for the second array.
 * @returns The interpolated array.
 */
function interArray(a1: any, a2: any, w1: number, w2: number): any {
  return a1.map((val: any, index: number) =>
    linearInter(val, a2[index], w1, w2)
  );
}

export {
  useRecording,
  // Todo: refactor so that recordResult is also part of useRecording
  recordResult,
};
