/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useState, useRef, useCallback, useEffect } from "react";
import { checkMoCapCore, runMoCapCore } from "../motion-capture/core";
import { useDialog } from "../ui-components/Dialog/DialogContextProvider";
import { IEmbeddingResult } from "../types/motion-capture";
import { signal } from "@preact/signals-react";

export const idealVideoWidthSignal = signal(480);
export const webCamWasRunningSignal = signal(false);

/**
 * Custom hook for accessing the webcam.
 *
 * @param options.videoElement - The reference to the HTMLVideoElement for displaying the webcam stream.
 * @param options.canvasElement - The reference to the HTMLCanvasElement for rendering the webcam stream.
 * @param options.videoWidth - The desired width of the video stream.
 * @param options.renderCallback - The callback function to be called with the embedding results.
 * @returns An object containing the webcam state and functions for controlling the webcam.
 */
function useWebCam({
  videoElement,
  canvasElement,
  videoWidth,
  renderCallback,
}: {
  videoElement: RefObject<HTMLVideoElement>;
  canvasElement: RefObject<HTMLCanvasElement>;
  videoWidth: number;
  renderCallback: (results: IEmbeddingResult) => void;
}) {
  // state for re-rendering dom elements on state change
  const [webCamRunning, setWebCamRunning] = useState(false);
  // ref for logic that depends on most current state each frame
  const webCamRunningRef = useRef(webCamRunning);

  const { showDialog } = useDialog();

  function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  const memoizedHasGetUserMedia = useCallback(hasGetUserMedia, []);

  function stopMediaStream(stream: MediaStream) {
    let tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  }

  const handleLoadedData = useCallback(() => {
    predictWebcam({
      videoElement: videoElement,
      canvasElement: canvasElement,
      videoWidth: videoWidth,
      renderCallback: renderCallback,
    });
  }, [videoElement, canvasElement, videoWidth, renderCallback]);

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.addEventListener("loadeddata", handleLoadedData);
    }
    return () => {
      if (videoElement.current) {
        videoElement.current.removeEventListener(
          "loadeddata",
          handleLoadedData
        );
      }
    };
  }, [videoElement, handleLoadedData]);

  const toggleWebCam = useCallback(() => {
    if (!checkMoCapCore()) {
      console.log("Wait! ML model not loaded yet.");
      return;
    }

    const newWebCamState = !webCamRunningRef.current;
    setWebCamRunning(newWebCamState);
    webCamRunningRef.current = newWebCamState;

    if (newWebCamState) {
      console.log("Starting webcam");
      // https://stackoverflow.com/a/48546227
      var constraints = {
        video: {
          width: { ideal: idealVideoWidthSignal.value },
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          if (!videoElement.current) return;
          videoElement.current.srcObject = stream;
        })
        .catch((error) => {
          const e = error as Error;
          showDialog({
            title: "Camera Permission denied",
            type: "error",
            content: `User denied camera access. Please reload the page and grant this web app permission to use your camera.`,
          });

          console.error(e);

          setWebCamRunning(false);
          webCamRunningRef.current = false;
        });
    } else {
      if (videoElement.current && videoElement.current.srcObject) {
        stopMediaStream(videoElement.current.srcObject as MediaStream);
      }
    }
  }, [videoElement, webCamRunning]);

  useEffect(() => {
    return () => {
      setWebCamRunning(false);
      webCamRunningRef.current = false;

      if (videoElement.current && videoElement.current.srcObject) {
        stopMediaStream(videoElement.current.srcObject as MediaStream);
      }
    };
  }, []);

  let results: IEmbeddingResult | undefined = undefined;
  let lastVideoTime = -1;

  /**
   * Predicts the webcam stream and performs rendering based on the provided parameters.
   * @param {Object} options - The options for predicting the webcam stream.
   * @param {RefObject<HTMLVideoElement>} options.videoElement - The reference to the HTMLVideoElement for the webcam stream.
   * @param {RefObject<HTMLCanvasElement>} options.canvasElement - The reference to the HTMLCanvasElement for rendering.
   * @param {number} options.videoWidth - The width of the video element.
   * @param {(results: IEmbeddingResult) => void} options.renderCallback - The callback function to handle the rendering results.
   * @returns {Promise<void>} - A promise that resolves when the prediction is complete.
   */
  async function predictWebcam({
    videoElement,
    canvasElement,
    videoWidth,
    renderCallback,
  }: {
    videoElement: RefObject<HTMLVideoElement>;
    canvasElement: RefObject<HTMLCanvasElement>;
    videoWidth: number;
    renderCallback: (results: IEmbeddingResult) => void;
  }) {
    try {
      if (!videoElement.current || !canvasElement.current) return;

      const ratio =
        videoElement.current.videoHeight / videoElement.current.videoWidth;

      videoElement.current.style.width = videoWidth + "px";
      videoElement.current.style.height = videoWidth * ratio + "px";
      videoElement.current.muted = true;
      canvasElement.current.width = videoElement.current.videoWidth;
      canvasElement.current.height = videoElement.current.videoHeight;

      // Now let's start detecting the stream.
      if (lastVideoTime !== videoElement.current.currentTime) {
        lastVideoTime = videoElement.current.currentTime;
        results = await runMoCapCore(videoElement.current);
      }

      if (webCamRunningRef.current) {
        if (results) renderCallback(results);

        // Call this function again to keep predicting when the browser is ready.
        window.requestAnimationFrame(() => {
          predictWebcam({
            videoElement: videoElement,
            canvasElement: canvasElement,
            videoWidth: videoWidth,
            renderCallback: renderCallback,
          });
        });
      }
    } catch (error) {
      console.error("predictWebcam error", error);
    }
  }

  return {
    webCamRunning,
    webCamRunningRef,
    toggleWebCam,
    hasGetUserMedia: memoizedHasGetUserMedia,
  };
}

export { useWebCam };
