export default function IconRecord() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="8.55556"
        fill="white"
        stroke="white"
        strokeWidth="1.18519"
      />
      <circle
        cx="13"
        cy="13"
        r="12.4074"
        stroke="white"
        strokeWidth="1.18519"
      />
    </svg>
  );
}
