/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import IconImage from "../../svg/IconImage";
import IconLogout from "../../svg/IconLogout";
import IconRecord from "../../svg/IconRecord";
import IconSettings from "../../svg/IconSettings";
import IconSparkles from "../../svg/IconSparkles";
import { IVrmProps, vrms } from "../../vrm/vrm";
import { ButtonElement } from "./ButtonElement";
import { debounce } from "../../utility/utils";
import { SelectVRMWindow } from "../SelectVrmWindow";
import { ProfileButton } from "../../ui-components/ProfileButton";
import { useIsMobile } from "../../utility/useIsMobile";

function SeparatorV() {
  return (
    <div
      className={`mx-2 w-[.075rem] border-primarygreen h-full bg-primarygreen`}
    />
  );
}

function SeparatorH() {
  return <hr className={`mx-2 border-primarygreen h-2 w-6`} />;
}

function MainSectionMobile({
  imgButtonState,
  settingsButtonState,
  selectVrmButtonState,
  selectVrmButtonRef,
}: ContextBarProps & { selectVrmButtonRef: React.RefObject<HTMLDivElement> }) {
  return (
    <div className={`flex flex-col gap-8 items-center h-full `}>
      <ButtonElement
        buttonState={selectVrmButtonState}
        ref={selectVrmButtonRef}
      >
        <IconSparkles />
      </ButtonElement>
      <ButtonElement buttonState={imgButtonState}>
        <IconImage />
      </ButtonElement>
      <ButtonElement>
        <IconRecord />
      </ButtonElement>
      <div className={`flex-grow`}></div>
      <ButtonElement buttonState={settingsButtonState}>
        <IconSettings />
      </ButtonElement>
      <SeparatorH />
      <ButtonElement>
        <IconLogout />
      </ButtonElement>
    </div>
  );
}

export function MainSectionSm({
  imgButtonState,
  settingsButtonState,
  selectVrmButtonState,
  selectVrmButtonRef,
}: ContextBarProps & {
  selectVrmButtonRef?: React.RefObject<HTMLDivElement | null>;
}) {
  return (
    <div className={`flex flex-col gap-8 mt-10 p-1 items-center h-full`}>
      <ProfileButton classNameButton={`w-14 h-auto`} />
      <ButtonElement
        buttonState={selectVrmButtonState}
        ref={selectVrmButtonRef}
      >
        <IconSparkles />
      </ButtonElement>
      <ButtonElement buttonState={imgButtonState}>
        <IconImage />
      </ButtonElement>
      <ButtonElement>
        <IconRecord />
      </ButtonElement>
      <div className={`flex-grow`}></div>
      <ButtonElement buttonState={settingsButtonState}>
        <IconSettings />
      </ButtonElement>
      <SeparatorH />
      <ButtonElement>
        <IconLogout />
      </ButtonElement>
    </div>
  );
}

function LogoSectionMobile() {
  return (
    <div className={`flex flex-row gap-6 justify-between w-full`}>
      <div className={`w-10 h-10`}>
        <img
          src="/img/alias-logo-green.png"
          alt={`Alias Logo`}
          className={`w-full h-full object-cover`}
        />
      </div>
      <div className={`w-10 h-10`}>
        <ProfileButton />
      </div>

      {/* <ButtonElement>
        <img
          src={"/img/profile-pic.png"}
          className={`border-2 border-primarygreen rounded-full w-10`}
          alt={"Profile"}
        />
      </ButtonElement> */}
    </div>
  );
}

export function LogoSectionSm() {
  return (
    <div className={`flex flex-col gap-6`}>
      <div className={`w-10 h-10`}>
        <img src="/img/alias-logo-green.png" alt={`Alias Logo`} />
      </div>
      <SeparatorH />
    </div>
  );
}

interface ContextBarProps {
  imgButtonState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  settingsButtonState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  selectVrmButtonState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  selectedVrm?: IVrmProps;
  setSelectedVrm?: React.Dispatch<React.SetStateAction<IVrmProps>>;
}

export default function ContextBar({
  imgButtonState,
  settingsButtonState,
  selectVrmButtonState,
  selectedVrm,
  setSelectedVrm,
}: ContextBarProps) {
  const selectVrmButtonRef = useRef<HTMLDivElement | null>(null);
  const [selectVrmWindowPos, setselectVrmWindowPos] = useState({
    top: 0,
    left: 0,
  });

  // detect mobile
  const { isMobile } = useIsMobile();

  // calculate position of select vrm window
  useEffect(() => {
    if (!selectVrmButtonState) return;

    if (selectVrmButtonState[0] && selectVrmButtonRef.current) {
      const rect = selectVrmButtonRef.current.getBoundingClientRect();
      setselectVrmWindowPos({
        top: rect.top + window.scrollY,
        left: rect.right + window.scrollX + rect.width / 2,
      });
    }
  }, [selectVrmButtonState]);

  return (
    <>
      {isMobile ? (
        <div className={`block`}>
          <div className={`fixed w-full h-full gap-6 flex flex-col p-5 z-20`}>
            <LogoSectionMobile />
            <div className={`self-start h-full`}>
              <MainSectionMobile
                selectVrmButtonState={selectVrmButtonState}
                imgButtonState={imgButtonState}
                settingsButtonState={settingsButtonState}
                selectVrmButtonRef={selectVrmButtonRef}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={`block`}>
          <div
            className={`fixed w-20 h-[100vh] bg-charcoal flex flex-col p-5 py-10 z-20`}
          >
            <LogoSectionSm />
            <MainSectionSm
              selectVrmButtonState={selectVrmButtonState}
              imgButtonState={imgButtonState}
              settingsButtonState={settingsButtonState}
              selectVrmButtonRef={selectVrmButtonRef}
            />
          </div>
        </div>
      )}

      <SelectVRMWindow
        selectedVrm={selectedVrm}
        setSelectedVrm={setSelectedVrm}
        selectVrmButtonState={selectVrmButtonState}
      />
    </>
  );
}
