/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  createContext,
  SetStateAction,
  Dispatch,
  useContext,
} from "react";
import { initMoCapCore } from "../motion-capture/core";
import {
  idealVideoWidthSignal,
  useWebCam,
  webCamWasRunningSignal,
} from "../webcam/useWebCam";
import * as THREE from "three";
import {
  connectBlendShapes,
  connectBodyPositions,
  connectBoneRotates,
} from "../vrm/blendshapes";
import { useRecording } from "../motion-capture/record";
import MainCanvas from "../canvas/MainCanvas";
import VrmComponent from "../vrm/VrmComponent";
import { Leva, useControls } from "leva";
import { Perf } from "r3f-perf";
import { useCanvasRecorder } from "../canvas/canvasRecording";
import { IVrmProps, vrms } from "../vrm/vrm";
import { TabMenuBackground } from "../ui-components/TabMenuBackground";
import {
  BackgroundProps,
  availableBackgrounds,
} from "../background/Background";
import { BottomBar } from "../dom/BottomBar/BottomBar";
import TopBar, { profileButtonSignal } from "../dom/TopBar/TopBar";
import usePrevious from "../utility/usePrevious";
import { SelectVRMWindow } from "../dom/SelectVrmWindow";
import { IEmbeddingResult } from "../types/motion-capture";
import { VRM } from "@pixiv/three-vrm";
import { OrbitControls } from "@react-three/drei";
import { LoadingIndicator } from "../dom/LoadingIndicator/LoadingIndicator";
import { useTutorial } from "../dom/Tutorial/useTutorial";
import { useSetup } from "../dom/Tutorial/useSetup";

// Todo: convert record state to signal
export type RecordState =
  | "initializing"
  | "recording-ml"
  | "stopped"
  | "recording-canvas"
  | "viewing";

interface RecordingContextType {
  toggleWebCam: () => void;
  webCamRunning: boolean;
  recordingState: RecordState;
  setRecordingState: Dispatch<SetStateAction<RecordState>>;
}

// Create the context with a default value
const RecordingContext = createContext<RecordingContextType>({
  toggleWebCam: () => {},
  webCamRunning: false,
  recordingState: "initializing",
  setRecordingState: () => {},
});

export const useRecordingContext = () => {
  const context = useContext(RecordingContext);
  if (!context) {
    throw new Error("useRecording must be used within a RecordingProvider");
  }
  return context;
};

export default function RecordMode() {
  const videoWidth = useRef(480);

  const videoElement = useRef<HTMLVideoElement | null>(null);
  const videoCanvasElement = useRef<HTMLCanvasElement | null>(null);

  const threeCanvasElement = useRef<HTMLCanvasElement | null>(null);

  // The selected VRM from the UI
  const [selectedVrm, setSelectedVrm] = useState<IVrmProps>(vrms[0]);

  // The actual VRM model when loaded by VRM component
  const [currentVRM, setCurrentVRM] = useState<VRM | null>(null);

  const defaultXYZ = useRef<THREE.Vector3 | null>(null);

  const recordState = useState<RecordState>("initializing");
  const [recording, setRecordState] = recordState;

  const previousRecordingState = usePrevious(recording);

  const bgWindowState = useState(false);
  const [bgWindowVisible, setWindowBgVisible] = bgWindowState;

  const vrmWindowState = useState(false);
  const [vrmWindowVisible, setWindowVrmVisible] = vrmWindowState;

  const [currentBackground, setCurrentBackground] = useState<BackgroundProps>(
    availableBackgrounds["Static Image"][0]
  );

  const settingsState = useState(false);
  const [settingsVisible, setSettingsVisible] = settingsState;

  const toolTipWindowState = useState(false);
  const [toolTipWindowVisible, setToolTipWindowVisible] = toolTipWindowState;

  const clock: THREE.Clock = useMemo((): THREE.Clock => {
    return new THREE.Clock();
  }, []);

  const runConnectBlendshapesRef = useRef(true);

  const showVideoCanvas = false;

  /**
   * Debug options
   */
  const {
    perfomanceMonitor,
    runConnectBlendshapes,
    displayModel,
    orbitControls,
    idealVideoWidth,
  } = useControls({
    perfomanceMonitor: false,
    runConnectBlendshapes: true,
    displayModel: true,
    orbitControls: false,
    idealVideoWidth: 480,
  });

  useEffect(() => {
    idealVideoWidthSignal.value = idealVideoWidth;
  }, [idealVideoWidth]);

  useEffect(() => {
    runConnectBlendshapesRef.current = runConnectBlendshapes;
  }, [runConnectBlendshapes]);

  /**
   * Unified VRM rendering function called from both webcam and record functionality
   * Renders the VRM based on the provided results.
   * @param {IEmbeddingResult} param.results - The embedding results.
   */
  function renderVRM({ results }: { results: IEmbeddingResult }) {
    if (!currentVRM || !results) return;

    if (runConnectBlendshapesRef.current) {
      connectBlendShapes(results.faceBlendshapes, currentVRM, selectedVrm);
      connectBoneRotates(results.boneRotates, currentVRM);
      connectBodyPositions(
        results.bodyPositions,
        currentVRM,
        defaultXYZ.current ? defaultXYZ.current : new THREE.Vector3(0, 0, 0)
      );

      if (currentVRM) {
        if (selectedVrm.name === "Cypher") {
          currentVRM.scene.rotation.x = selectedVrm.rotation.x;
          currentVRM.scene.rotation.y = selectedVrm.rotation.y;
          currentVRM.scene.rotation.z = selectedVrm.rotation.z;
        }
      }

      currentVRM.update(clock.getDelta());
    }
  }

  /**
   * Initialize hook for webcam related logic, with a callback to the rendering logic
   * @param videoElement The video element used for displaying the webcam feed
   * @param canvasElement The canvas element used for rendering the video frames
   * @param videoWidth The width of the video feed
   * @param renderCallback The callback function to be called when the embedding results are available
   * @returns An object containing the webcam toggle state, a reference to the webcam toggle state, and a flag indicating if getUserMedia is supported
   */
  const { toggleWebCam, webCamRunning, webCamRunningRef, hasGetUserMedia } =
    useWebCam({
      videoElement,
      canvasElement: videoCanvasElement,
      videoWidth: videoWidth.current,
      renderCallback: (results: IEmbeddingResult) => {
        if (!results) return;

        renderVRM({ results });

        // }
      },
    });

  /**
   * Delays the restart of the webcam.
   */
  const delayedRestartWebCam = () => {
    setTimeout(() => {
      toggleWebCam();
    }, 250);
  };

  /**
   * Initializes and manages the recording functionality.
   * @param renderCallback The callback function to be called when the embedding results are available
   */
  const {
    capturedRecording,
    startRecording,
    stopRecording,
    viewRecording,
    stopPlaybackLoop,
  } = useRecording({
    renderCallback: (results: IEmbeddingResult) => {
      if (!results) return;

      renderVRM({ results });
    },
    recordState,
  });

  /**
   * Function to view the recording.
   * If a recording is available, it toggles the web camera and then views the recording.
   */
  function viewRecording_() {
    if (capturedRecording) {
      if (webCamRunningRef.current) {
        toggleWebCam();
      }

      viewRecording();
    } else {
      console.log("Wait! No recording saved yet.");
    }
  }

  /**
   * Component for recording canvas and audio.
   *
   * @remarks
   * This component provides functions for starting and stopping canvas and audio recording,
   * as well as playing back the recorded audio. It also exposes the recording URL.
   */
  const {
    isCanvasRecording,
    startAudioRecording,
    stopAudioRecording,
    isAudioPlaying,
    playAudioRecording,
    stopAudioPlayback,
    startCanvasRecording,
    stopCanvasRecording,
    recordingUrl,
  } = useCanvasRecorder();

  /**
   * Initializes the motion capture core and starts the clock.
   * Checks if the browser supports getUserMedia.
   */
  useEffect(() => {
    initMoCapCore();

    if (!hasGetUserMedia()) {
      console.warn("getUserMedia() is not supported by your browser");
    }

    clock.start();
  }, [clock, hasGetUserMedia]);

  /**
   * Manages the logic for recording, viewing, and stopping recordings.
   */
  useEffect(() => {
    // Logic for handling different recording states
    if (recording === "initializing") {
      // Handle initializing state
    }

    if (recording === "recording-ml") {
      // Start ML recording and audio recording
      startRecording();
      startAudioRecording();
    }

    if (recording === "stopped") {
      // Handle stopped state based on previous recording state
      if (previousRecordingState === "recording-ml") {
        stopRecording();
        stopAudioRecording();
      } else if (previousRecordingState === "recording-canvas") {
        stopCanvasRecording();
      } else if (previousRecordingState === "viewing") {
        stopPlaybackLoop();
        stopAudioPlayback();

        // Restart webcam if necessary
        if (webCamWasRunningSignal.value || !webCamRunningRef.current) {
          delayedRestartWebCam();
        }
      }
    }

    if (recording === "viewing") {
      // Play audio recording and view recording
      webCamWasRunningSignal.value = webCamRunningRef.current;
      playAudioRecording();
      viewRecording_();
    }

    if (recording === "recording-canvas") {
      // Stop playback loop, start canvas recording, and view recording
      stopPlaybackLoop();
      startCanvasRecording(threeCanvasElement.current);
      viewRecording_();
    }
  }, [recording]);

  useEffect(() => {
    // Restart webcam if necessary when currentVRM changes
    if (currentVRM) {
      if (webCamWasRunningSignal.value && !webCamRunningRef.current) {
        delayedRestartWebCam();
      }
    }
  }, [currentVRM]);

  const { tutorialState } = useTutorial();
  useSetup({ tutorialState, webCamRunningRef, toggleWebCam, currentVRM });

  return (
    <>
      <RecordingContext.Provider
        value={{
          toggleWebCam: toggleWebCam,
          webCamRunning: webCamRunning,
          recordingState: recordState[0],
          setRecordingState: recordState[1],
        }}
      >
        <div
          className={`fixed ${
            showVideoCanvas ? "" : "invisible pointer-events-none"
          } top-0 left-0`}
        >
          <video id="webcam" autoPlay playsInline ref={videoElement}></video>
          <canvas id="output_canvas" ref={videoCanvasElement}></canvas>
        </div>
        <div className={`fixed top-0 left-0 -z-50 w-[100vw] h-[100vh]`}>
          <MainCanvas
            ref={threeCanvasElement}
            currentBackground={currentBackground}
          >
            {displayModel && (
              <VrmComponent
                selectedVrm={selectedVrm}
                currentVrm={currentVRM}
                setCurrentVrm={setCurrentVRM}
                zOffset={0.75}
                defaultXYZ={defaultXYZ}
              />
            )}

            {orbitControls && <OrbitControls />}

            {perfomanceMonitor && <Perf />}
          </MainCanvas>
        </div>
        <LoadingIndicator />
        <TopBar settingsState={settingsState} />
        <BottomBar
          settingsState={settingsState}
          bgWindowState={bgWindowState}
          vrmWindowState={vrmWindowState}
          selectedVrm={selectedVrm}
          setSelectedVrm={setSelectedVrm}
          recordingUrl={recordingUrl}
        />
        {bgWindowVisible && (
          <>
            <div
              className={`w-full max-h-full h-full flex flex-col items-center`}
            >
              <div
                className={`w-full block fixed top-0 h-screen bg-black/10 backdrop-blur-md z-30`}
              ></div>
              <TabMenuBackground
                curBackground={currentBackground}
                className={`z-50 mt-20`}
                setCurBackground={setCurrentBackground}
                setBgWindowVisible={setWindowBgVisible}
              />
            </div>
          </>
        )}
        <SelectVRMWindow
          selectedVrm={selectedVrm}
          setSelectedVrm={setSelectedVrm}
          selectVrmButtonState={vrmWindowState}
        />

        <Leva hidden={!settingsVisible} />
      </RecordingContext.Provider>
    </>
  );
}
