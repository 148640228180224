import { CSSProperties, useState } from "react";
import { Tab } from "@headlessui/react";
import {
  BackgroundProps,
  availableBackgrounds,
} from "../background/Background";
import { useToolTip } from "./ToolTip/ToolTipContextProvider";
import { HexColorPicker } from "react-colorful";
import { effect, signal } from "@preact/signals-react";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type CategoriesData = {
  [key: string]: BackgroundProps[];
};

export const customColorSignal = signal(availableBackgrounds.Chroma[2].color);

export function TabMenuBackground({
  curBackground,
  className,
  setCurBackground,
  setBgWindowVisible,
}: {
  curBackground: BackgroundProps;
  className?: string;
  setCurBackground: (val: BackgroundProps) => void;
  setBgWindowVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [categories] = useState<CategoriesData>(availableBackgrounds);
  const { showToolTip } = useToolTip();

  effect(() => {
    availableBackgrounds.Chroma[2].color = customColorSignal.value;
  });

  return (
    <>
      <div className={`w-full max-w-md px-4 sm:px-0 ${className} text-white`}>
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-white/10 backdrop-blur-md p-1">
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                    "ring-white/60 focus:outline-none",
                    selected
                      ? "bg-primarygreen shadow text-black"
                      : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.entries(categories).map(([category, backgrounds]) => (
              <Tab.Panel
                key={category}
                className={classNames(
                  "rounded-xl bg-white/10 backdrop-blur-md p-3",
                  "ring-white/60 focus:outline-none"
                )}
              >
                <div className={`flex flex-col gap-2`}>
                  {backgrounds.map((background) => {
                    const isCurrent = background.url === curBackground.url;

                    let style: CSSProperties = {};

                    if (background.type === "Chroma Key") {
                      style.backgroundColor = background.color;
                    }

                    if (
                      background.type === "360 Image" ||
                      background.type === "Static Image" ||
                      background.type === "Video"
                    ) {
                      style.backgroundImage = `url(${
                        background.url
                          ? background.url
                          : availableBackgrounds["Static Image"][0].url
                      })`;
                      style.backgroundPosition = "center";
                      style.backgroundSize = "cover";
                    }

                    if (background.type === "Video") {
                    }

                    return (
                      <div
                        key={`${category}-${background.title}`}
                        className={`relative rounded-md p-3 hover:bg-gray-100 cursor-pointer hover:brightness-150 transition-all ${
                          isCurrent ? "ring-1 ring-primarygreen" : ""
                        }`}
                        style={style}
                        onClick={() => {
                          if (
                            background.type === "Chroma Key" &&
                            background.title === "Custom"
                          ) {
                            showToolTip({
                              title: "Custom Color",
                              content: (
                                <div
                                  className={`flex flex-col justify-center items-center gap-4`}
                                >
                                  <CustomBgPicker />
                                </div>
                              ),
                              buttons: [
                                {
                                  label: "OK",
                                  onClick: () => {
                                    setCurBackground(background);
                                    if (setBgWindowVisible)
                                      setBgWindowVisible(false);
                                  },
                                },
                              ],
                              refElement: null,
                              options: {
                                placement: "top",
                                clickBgClose: false,
                              },
                            });
                          }
                          setCurBackground(background);
                          if (setBgWindowVisible) setBgWindowVisible(false);
                        }}
                      >
                        <div className="text-sm font-medium leading-5 backdrop-blur-sm bg-black/20 rounded-lg p-4 flex flex-col w-fit">
                          <div>{background.title}</div>
                          <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-200">
                            <li>{background.collection}</li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}

function CustomBgPicker() {
  const handleColorChange = (color: string) => {
    customColorSignal.value = color;
  };

  return (
    <HexColorPicker
      color={customColorSignal.value}
      onChange={handleColorChange}
    />
  );
}
