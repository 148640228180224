import { motion } from "framer-motion";

function Button({
  label,
  className,
  onClick,
}: {
  label: string | React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div>
      <motion.div
        whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
        className={`bg-black rounded-full min-w-[5rem] select-none px-8 h-16 text-white transition-colors hover:text-primarygreen items-center p-4 cursor-pointer relative inline-flex font-purista ${className}`}
        onClick={onClick && onClick}
      >
        <div className={`flex justify-center items-center w-full h-auto`}>
          <div className={`h-auto`}>{label}</div>
        </div>
      </motion.div>
    </div>
  );
}

export { Button };
