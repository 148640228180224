import { Switch } from "@headlessui/react";
import { useState } from "react";

interface ToggleSwitchProps {
  labelEnabled?: string;
  labelDisabled?: string;
  className?: string;
  value?: boolean;
  justify?: boolean;
  onChange?: (value: boolean) => void;
}

export function ToggleSwitch({
  labelEnabled,
  labelDisabled,
  className,
  justify = false,
  value: externalValue,
  onChange,
}: ToggleSwitchProps) {
  const [internalEnabled, setInternalEnabled] = useState(false);

  const enabled = externalValue !== undefined ? externalValue : internalEnabled;

  const handleSwitchChange = (value: boolean) => {
    if (onChange) {
      onChange(value);
    }
    setInternalEnabled(value);
  };

  return (
    <Switch.Group>
      <div
        className={`flex items-center ${justify ? "justify-between" : ""} ${
          className && className
        }`}
      >
        <Switch.Label className="mr-4">
          {enabled
            ? labelEnabled && labelEnabled
            : labelDisabled && labelDisabled}
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={handleSwitchChange}
          className={`${
            enabled ? "bg-primarygreen" : "bg-gray-500"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
        >
          <span
            className={`${
              enabled ? "translate-x-6 bg-charcoal" : "translate-x-1 bg-white"
            } inline-block h-4 w-4 transform rounded-full transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
