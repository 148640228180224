/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  VRMHumanBoneName,
  VRMLoaderPlugin,
  VRMUtils,
  VRM,
} from "@pixiv/three-vrm";
import { useEffect, useMemo, useState, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { initScale } from "./blendshapes";
import { useThree, useLoader } from "@react-three/fiber";
import React from "react";
import { IVrmProps } from "./vrm";
import { signal } from "@preact/signals-react";

interface ILoadingState {
  isLoading: boolean;
  progress: number;
}

interface IModelCache {
  [key: string]: GLTF;
}

export const loadingStateSignal = signal<ILoadingState>({
  isLoading: false,
  progress: 0,
});

export const modelCacheSignal = signal<IModelCache>({});

/**
 * Loads a 3D model from the specified URL using the GLTFLoader and VRMLoaderPlugin.
 * @param url - The URL of the model to load.
 * @param abortSignal - The AbortSignal used to abort the loading process.
 * @returns A Promise that resolves to the loaded GLTF model or null if the loading was aborted.
 */
export const loadModel = async (
  url: string,
  abortSignal: AbortSignal
): Promise<GLTF | null> => {
  if (modelCacheSignal.value[url]) {
    return modelCacheSignal.value[url];
  }

  const loader = new GLTFLoader();
  loader.register((parser) => new VRMLoaderPlugin(parser));

  return new Promise((resolve, reject) => {
    if (abortSignal.aborted) {
      reject(new Error("Loading aborted"));
      return;
    }

    loadingStateSignal.value = { isLoading: true, progress: 0 };

    loader.load(
      url,
      (gltf) => {
        if (abortSignal.aborted) {
          return;
        }
        resolve(gltf);
        modelCacheSignal.value[url] = gltf;
        loadingStateSignal.value = { isLoading: false, progress: 100 };
      },
      (progressEvent) => {
        if (abortSignal.aborted) {
          reject(new Error("Loading aborted"));
          return;
        }
        if (progressEvent.lengthComputable) {
          const percentComplete =
            (progressEvent.loaded / progressEvent.total) * 100;
          loadingStateSignal.value = {
            isLoading: true,
            progress: percentComplete,
          };
        }
      },
      (error) => {
        if (!abortSignal.aborted) {
          console.error("Error loading model:", error);
          reject(error);
        }
      }
    );

    abortSignal.addEventListener("abort", () => {
      if (loadingStateSignal.value.isLoading) {
        console.log(`Loading aborted:${url}`);
        loadingStateSignal.value = { isLoading: false, progress: 0 };
        reject(new Error("Loading aborted"));
      }
    });
  });
};

/**
 * Renders a VRM model with the specified props.
 *
 * @param selectedVrm - The selected VRM props.
 * @param setCurrentVrm - The function to set the current VRM.
 * @param setCameraPosition - The function to set the camera position.
 * @param defaultXYZ - The default XYZ position.
 * @returns The rendered VRM model.
 */
function VrmModel({
  selectedVrm,
  setCurrentVrm,
  setCameraPosition,
  defaultXYZ,
}: {
  selectedVrm: IVrmProps;
  setCurrentVrm: React.Dispatch<React.SetStateAction<VRM | null>>;
  setCameraPosition: (pos: THREE.Vector3) => void;
  defaultXYZ: React.MutableRefObject<THREE.Vector3 | null>;
}) {
  const [gltf, setGltf] = useState<GLTF | null>(null);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    const load = async () => {
      try {
        const loadedModel = await loadModel(
          selectedVrm.url,
          abortController.current.signal
        );
        if (loadedModel) {
          setGltf(loadedModel);
        }
      } catch (error: any) {
        if (error.message !== "Loading aborted") {
          console.error("VRM loading error:", error);
        }
      }
    };

    if (modelCacheSignal.value[selectedVrm.url]) {
      setGltf(modelCacheSignal.value[selectedVrm.url]);
    } else {
      abortController.current.abort();
      abortController.current = new AbortController();
      load();
    }

    return () => {
      abortController.current.abort();
    };
  }, [selectedVrm.url]);

  const TVRMSHBN = useMemo(() => VRMHumanBoneName, []);
  const defaultPose = useMemo(
    () => [
      [TVRMSHBN.LeftUpperArm, [0, 0, 70]],
      [TVRMSHBN.RightUpperArm, [0, 0, -70]],
      [TVRMSHBN.LeftLowerArm, [-20, -30, 10]],
      [TVRMSHBN.RightLowerArm, [-20, 30, -10]],
      [TVRMSHBN.LeftHand, [0, 0, 0]],
      [TVRMSHBN.RightHand, [0, 0, 0]],
    ],
    [TVRMSHBN]
  );

  useEffect(() => {
    if (gltf) {
      VRMUtils.removeUnnecessaryVertices(gltf.scene);
      VRMUtils.removeUnnecessaryJoints(gltf.scene);
      const vrm = gltf.userData.vrm as VRM;

      if (!vrm) {
        console.error("VRM not found in GLTF userData");
        return;
      }

      let VRM_R = [1, 1, 1];
      if (vrm.meta.metaVersion === "0") {
        console.log("VRM-0");
        VRM_R = [1, 1, 1];
      } else if (vrm.meta.metaVersion === "1") {
        console.log("VRM-1");
        VRM_R = [-1, 1, -1];
      }

      for (let i = 0; i < defaultPose.length; i++) {
        let pose = defaultPose[i];
        for (let j = 0; j < 3; j++) {
          //@ts-ignore
          vrm.humanoid.getNormalizedBoneNode(pose[0]).rotation["xyz"[j]] =
            //@ts-ignore
            ((pose[1][j] * Math.PI) / 180) * VRM_R[j];
        }
      }

      vrm.scene.rotation.y = Math.PI;

      const headBone = vrm.humanoid.getNormalizedBoneNode(
        VRMHumanBoneName.Head
      );
      if (!headBone) {
        console.error("Head bone node not found");
        return;
      }

      const eyePosition = new THREE.Vector3();
      headBone.getWorldPosition(eyePosition);
      eyePosition.y += 0.1; // Todo: Adjust per VRM model

      setCameraPosition(eyePosition);

      const hips = vrm.humanoid.getNormalizedBoneNode(VRMHumanBoneName.Hips);

      if (!hips) {
        console.error("Head bone node not found");
        return;
      }

      if (vrm.meta.metaVersion === "1") {
        hips.rotation.y = Math.PI;
      }

      const hipVector = new THREE.Vector3(
        hips.position.x,
        hips.position.y,
        hips.position.z
      );
      defaultXYZ.current = hipVector;

      setCurrentVrm(vrm);
      console.log(vrm);
    }
  }, [gltf]);

  if (!gltf) return null;

  return (
    <>
      {!loadingStateSignal.value.isLoading && gltf && (
        <primitive object={gltf.userData.vrm.scene} />
      )}
    </>
  );
}

/**
 * Renders a VRM component.
 *
 * @param selectedVrm - The selected VRM props.
 * @param currentVrm - The current VRM object.
 * @param setCurrentVrm - The function to set the current VRM object.
 * @param zOffset - The z-axis offset.
 * @param visible - Whether the component is visible or not.
 * @param defaultXYZ - The default XYZ position.
 * @returns The rendered VRM component.
 */
function VrmComponent({
  selectedVrm,
  currentVrm,
  setCurrentVrm,
  zOffset = 1,
  visible = true,
  defaultXYZ,
}: {
  selectedVrm: IVrmProps;
  currentVrm: VRM | null;
  setCurrentVrm: React.Dispatch<React.SetStateAction<VRM | null>>;
  zOffset?: number;
  visible?: boolean;
  defaultXYZ: React.MutableRefObject<THREE.Vector3 | null>;
}) {
  const { camera } = useThree();

  function setCameraPosition(pos: THREE.Vector3) {
    camera.position.set(
      pos.x + selectedVrm.offset.x,
      pos.y + selectedVrm.offset.y,
      pos.z + selectedVrm.offset.z + zOffset
    );
  }

  useEffect(() => {
    // Todo: Adjust per model
    initScale("vrm/Ekko_config.json");
  }, [currentVrm]);

  useEffect(() => {
    console.log("selectedVrm changed:", selectedVrm.name);
  }, [selectedVrm]);

  return (
    <group visible={visible}>
      <group scale={[-1, 1, 1]}>
        <VrmModel
          selectedVrm={selectedVrm}
          setCurrentVrm={setCurrentVrm}
          setCameraPosition={setCameraPosition}
          defaultXYZ={defaultXYZ}
        />
      </group>
    </group>
  );
}

export default VrmComponent;
