import {
  BackgroundProps,
  availableBackgrounds,
} from "../../background/Background";
import { TabMenuBackground } from "../../ui-components/TabMenuBackground";
import { UISection } from "../../ui-components/UISection";
import { useState } from "react";

export function BackgroundTabSection() {
  const [currentBackground, setCurrentBackground] = useState<BackgroundProps>(
    availableBackgrounds["Static Image"][0]
  );

  return (
    <UISection
      title="Tab Menu"
      mountButtonEnabled={false}
      backgroundImage={currentBackground.url}
      classNameContainer="p-8 min-h-[50vh]"
    >
      <TabMenuBackground
        curBackground={currentBackground}
        setCurBackground={setCurrentBackground}
      />
    </UISection>
  );
}
