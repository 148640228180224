/* eslint-disable @typescript-eslint/no-unused-vars */
// Motion Capture Core - Bone Rotate

import {
  FaceLandmarkerResult,
  NormalizedLandmark,
} from "@mediapipe/tasks-vision";
import { IBoneRotate } from "../types/motion-capture";

let headkey = [127, 356, 10, 152, 168];

/**
 * Calculates the slope between two points in a 2D coordinate system.
 *
 * @param xIdx - The index of the x-coordinate in the point arrays.
 * @param yIdx - The index of the y-coordinate in the point arrays.
 * @param p1 - The first point coordinates [x, y].
 * @param p2 - The second point coordinates [x, y].
 * @returns The slope between the two points.
 */
function slope(xIdx: number, yIdx: number, p1: number[], p2: number[]) {
  return (p2[yIdx] - p1[yIdx]) / (p2[xIdx] - p1[xIdx]);
}

/**
 * Calculates the 3D distance between two points in space.
 * @param p1 - The coordinates of the first point.
 * @param p2 - The coordinates of the second point. Defaults to [0, 0, 0].
 * @returns The distance between the two points.
 */
function distance3d(p1: number[], p2: number[] = [0, 0, 0]) {
  const horiz = p2[0] - p1[0];
  const vert = p2[1] - p1[1];
  const depth = p2[2] - p1[2];
  return Math.sqrt(horiz * horiz + vert * vert + depth * depth);
}

/**
 * Calculates the rotation angles of the head based on the given coordinates.
 * @param head - The coordinates of the head as a 2D array.
 * @returns An array containing the pitch, yaw, and roll angles in radians.
 */
function getHeadRotation(head: number[][]) {
  let rollSlope = slope(0, 1, head[1], head[0]);
  let roll = Math.atan(rollSlope);
  let yawSlope = slope(0, 2, head[1], head[0]);
  let yaw = Math.atan(yawSlope);
  let pitchSlope = slope(2, 1, head[2], head[3]);
  let pitch = Math.atan(pitchSlope);
  if (pitch > 0) {
    pitch -= Math.PI;
  }
  return [pitch + Math.PI / 2, yaw, roll];
}

/**
 * Packs the head points from the given array of normalized landmarks.
 * @param result - The array of normalized landmarks.
 * @returns An array of packed head points.
 */
function packHeadPoints(result: NormalizedLandmark[]): number[][] {
  function pointUnpack(p: NormalizedLandmark): number[] {
    return [p.x, p.y, p.z];
  }

  let head = [];
  for (let i = 0; i < headkey.length; i++) {
    head[i] = pointUnpack(result[headkey[i]]);
  }
  return head;
}

/**
 * Applies a ratio to each element of the given rotate array.
 * @param rotate - The rotate array to apply the ratio to.
 * @param ratio - The ratio to apply.
 * @returns The resulting array after applying the ratio.
 */
function applyRatio(rotate: number[], ratio: number) {
  return [rotate[0] * ratio, rotate[1] * ratio, rotate[2] * ratio];
}

/**
 * Retrieves the bone rotation data based on the given FaceLandmarkerResult.
 * @param result The FaceLandmarkerResult object containing the face landmarks.
 * @returns An array of IBoneRotate objects representing the bone rotations.
 */
function getBoneRotate(result: FaceLandmarkerResult): IBoneRotate[] {
  if (result.faceLandmarks.length === 0) {
    return [];
  }

  let head = packHeadPoints(result.faceLandmarks[0]);
  let headRotate = getHeadRotation(head);

  // Ensure that applyRatio returns a tuple [number, number, number]
  const formatRotation = (rotation: number[]): [number, number, number] => {
    return [rotation[0], rotation[1], rotation[2]];
  };

  return [
    {
      head: formatRotation(applyRatio(headRotate, 0.5)),
      neck: formatRotation(applyRatio(headRotate, 0.4)),
      spine: formatRotation(applyRatio(headRotate, 0.1)),
    },
  ];
}

export { getBoneRotate };
