import {
  createContext,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

// Button state can be read in children components to react to state changes
const ButtonStateContext = createContext<boolean | undefined>(undefined);

const ButtonElement = forwardRef(
  (
    {
      children,
      className,
      buttonState,
    }: {
      children: React.ReactNode;
      className?: string;
      buttonState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => internalRef.current!);

    const [state, setState] = buttonState ? buttonState : [false, () => {}];

    useEffect(() => {}, [state]);

    return (
      <div
        ref={internalRef}
        className={`cursor-pointer ${className && className} overflow-visible`}
        onClick={() => {
          setState(!state);
        }}
      >
        <ButtonStateContext.Provider value={state}>
          {children}
        </ButtonStateContext.Provider>
      </div>
    );
  }
);

export { ButtonElement, ButtonStateContext };
