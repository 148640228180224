/* eslint-disable @typescript-eslint/no-unused-vars */
import { VRM, VRMHumanBoneName } from "@pixiv/three-vrm";
import { Vector3 } from "three";
import {
  IBodyPosition,
  IBoneRotate,
  IUnifiedFaceBlendshape,
} from "../types/motion-capture";
import { IVrmProps } from "./vrm";

function possibleKeyMap(key: any) {
  return key[0].toUpperCase() + key.slice(1);
}

/**
 * Connects the blend shapes of a VRM model to the expression manager.
 * @param blendShapes - An array of blend shapes to connect.
 * @param currentVrm - The current VRM model.
 * @param selectedVRM - The selected VRM properties.
 */
function connectBlendShapes(
  blendShapes: IUnifiedFaceBlendshape[],
  currentVrm: VRM,
  selectedVRM: IVrmProps
) {
  if (!blendShapes.length) return;
  const expressionManager = currentVrm.expressionManager;

  // Check if expressionManager is defined
  if (!expressionManager) {
    console.error("Expression Manager not found");
    return;
  }

  blendShapes.forEach((blendShape) => {
    Object.keys(blendShape).forEach((key) => {
      if (typeof blendShape[key] === "number") {
        let scaleValue = getScale(key);
        let score = blendShape[key];
        let mappedKey = selectedVRM.blendshapesUpperCase
          ? possibleKeyMap(key)
          : key;
        expressionManager.setValue(mappedKey, score * scaleValue);
      }
    });
  });
}

/**
 * Connects bone rotations to the corresponding bones in a VRM model.
 *
 * @param boneRotations - An array of bone rotations to be applied.
 * @param currentVrmModel - The VRM model to apply the bone rotations to.
 */
function connectBoneRotates(
  boneRotations: IBoneRotate[],
  currentVrmModel: VRM
) {
  if (!boneRotations.length) return;
  const humanoid = currentVrmModel.humanoid;

  // Check if the humanoid is defined
  if (!humanoid) {
    console.error("Humanoid component not found in VRM model");
    return;
  }

  boneRotations.forEach((boneRotation) => {
    Object.entries(boneRotation).forEach(([boneName, rotationValues]) => {
      const normalizedBoneNode = humanoid.getNormalizedBoneNode(
        boneName as VRMHumanBoneName
      );

      if (normalizedBoneNode) {
        normalizedBoneNode.rotation.set(
          rotationValues[0],
          rotationValues[1],
          rotationValues[2]
        );
      } else {
        console.log("Bone not found:", boneName);
      }
    });
  });
}

/**
 * Connects body positions to the hips bone of a VRM model.
 *
 * @param bodyPositions - An array of body positions.
 * @param currentVrm - The current VRM model.
 * @param defaultXYZ - The default XYZ vector.
 */
function connectBodyPositions(
  bodyPositions: IBodyPosition[],
  currentVrm: VRM,
  defaultXYZ: Vector3
) {
  if (!bodyPositions.length) return;
  const Ch = currentVrm.humanoid;

  if (!Ch) {
    console.error("Humanoid not found in VRM");
    return;
  }

  const hipsNode = Ch.getNormalizedBoneNode(VRMHumanBoneName.Hips);

  if (!hipsNode) {
    console.error("Hips bone node not found in VRM");
    return;
  }

  const hips = hipsNode.position;

  if (!defaultXYZ) return;

  // Use the 'position' property from IBodyPosition
  let pos = bodyPositions[0].position;

  if (pos) {
    hips.x = defaultXYZ.x - pos.x * 0.5;
    hips.y = defaultXYZ.y - pos.y * 0.5;
    hips.z = defaultXYZ.z - pos.z * 2.0;
  }
}

// Scale Factor
let scales: any = {};
function getScale(key: any) {
  if (key in scales) {
    return scales[key];
  } else {
    scales[key] = 1.0;
    return 1.0;
  }
}
function setScale(key: any, value: any) {
  scales[key] = value;
  // console.log(key, value);
}
function initScale(file: any) {
  fetch(file)
    .then((response) => response.json())
    .then((data) => {
      Object.keys(data).forEach(function (key) {
        setScale(key, data[key]);
      });
    });
}
/**
 * Exports the scales as a JSON file.
 */
function exportScale() {
  let exportJSON = scales;
  let dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportJSON));
  let dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", "vrm_config.json");
  dlAnchorElem.click();
  dlAnchorElem.remove();
}

export {
  connectBlendShapes,
  connectBodyPositions,
  connectBoneRotates,
  initScale,
};
