import { Menu, Transition } from "@headlessui/react";
import { UsersIcon } from "@heroicons/react/20/solid";
import { ButtonElement } from "../dom/ContextBar/ButtonElement";

import { DropdownItem } from "./Dropdown/DropdownItem";
import IconSparkles from "../svg/IconSparkles";
import { ImageIcon } from "../dom/SideBar/SideBar";
import { Fragment, useImperativeHandle, useRef, forwardRef } from "react";

interface SparklesButtonProps {
  className?: string;
  classNameButton?: string;
  bgWindowState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  vrmWindowState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const SparklesButton = forwardRef<HTMLDivElement, SparklesButtonProps>(
  (
    {
      className,
      classNameButton = "h-14 w-auto",
      bgWindowState,
      vrmWindowState,
    },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => internalRef.current!);

    return (
      <div className={`relative ${className && className}`} ref={internalRef}>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="justify-center transition-colors rounded-md text-sm font-medium text-white">
            <ButtonElement
              className={`w-full h-full hover:brightness-125 transition-all duration-300`}
            >
              <div
                className={`w-8 h-auto aspect-square inline-flex justify-center`}
              >
                <IconSparkles />
              </div>
            </ButtonElement>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute -translate-y-[75%] bottom-0 left-0 mt-0 w-56 origin-bottom-right divide-y divide-primarygreen rounded-md bg-black/50 backdrop-blur-md shadow-lg ring-1 ring-black/5 focus:outline-none z-[30]">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <DropdownItem
                      label={`Change Alias`}
                      hover={active}
                      onClick={() => {
                        vrmWindowState && vrmWindowState[1](true);
                      }}
                      icon={<UsersIcon className={`w-4 h-auto`}></UsersIcon>}
                    ></DropdownItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <DropdownItem
                      label={`Change Background`}
                      hover={active}
                      onClick={() => {
                        bgWindowState && bgWindowState[1](!bgWindowState[0]);
                      }}
                      icon={<ImageIcon className={`w-4 h-auto`}></ImageIcon>}
                    ></DropdownItem>
                  )}
                </Menu.Item>
              </div>

              {/* <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <DropdownItem
                    hover={active}
                    icon={<MoonIcon className={`w-4 h-auto`}></MoonIcon>}
                  >
                    <ToggleSwitch
                      labelEnabled="Night Mode"
                      labelDisabled="Night Mode"
                      className={`w-full justify-between`}
                    />
                  </DropdownItem>
                )}
              </Menu.Item>
            </div> */}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  }
);

export { SparklesButton };
