import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { Image } from "../ui-components/Image";
import { GlobeAltIcon } from "@heroicons/react/20/solid";
import { CollectionCard } from "../ui-components/CollectionCard";
import { useIsMobile } from "../utility/useIsMobile";
import { BottomBar } from "../dom/BottomBar/BottomBar";

function SocialButton({
  icon,
  onClick,
}: {
  icon: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <div
      className={`w-auto h-5 sm:h-6 cursor-pointer hover:text-primarygreen transition-colors duration-300`}
      onClick={onClick && onClick}
    >
      {icon}
    </div>
  );
}

function SocialSection(
  { className }: { className?: string } = { className: "" }
) {
  return (
    <div
      className={`flex flex-col gap-2 justify-center rounded-full bg-black/10 backdrop-blur-md p-3 sm:p-4 px-8 sm:px-10 shadow-lg w-fit ${className}`}
    >
      <div className={`flex flex-row gap-6 items-center justify-center`}>
        <SocialButton
          icon={<AiOutlineTwitter className={`object-cover w-auto h-full`} />}
        />
        <SocialButton
          icon={<AiOutlineInstagram className={`object-cover w-auto h-full`} />}
        />
        <SocialButton
          icon={<GlobeAltIcon className={`object-cover w-auto h-full`} />}
        />
      </div>
    </div>
  );
}

export function Profile() {
  const { isMobile } = useIsMobile();

  return (
    <>
      <div
        className={`w-full min-h-full  text-white bg-charcoal font-ubuntu selection:bg-primarygreen selection:text-charcoal overflow-x-clip`}
      >
        {/* Top section with background image */}
        <div
          className={`h-[25vh] relative`}
          style={{
            backgroundImage: "url(/img/futuristic-bedroom.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={`flex flex-row gap-4 w-full h-24 sm:h-48 translate-y-1/4 px-8 absolute bottom-0 z-20 `}
          >
            {/* Profile Picture */}
            <div
              className={`rounded-full h-full w-auto aspect-square border-primarygreen border-4`}
            >
              <Image
                src={"/img/profile-pic.png"}
                alt={""}
                className={`object-cover h-full w-full`}
              />
            </div>

            {/* User Info */}
            <div className={`flex flex-col gap-2 sm:translate-y-10 grow`}>
              <div className={`flex flex-row justify-between`}>
                <div className={`flex flex-col px-4`}>
                  <div className={`text-4xl sm:text-5xl`}>{`Username`}</div>
                  <div className={`text-sm text-primarygreen`}>{`#101`}</div>
                </div>

                {!isMobile && <SocialSection className={``} />}
              </div>
            </div>
          </div>
          {isMobile && <SocialSection className={`right-0 absolute m-4`} />}
        </div>

        {/* Bottom section with collection cards */}
        {/* Todo: Fix margin bottom bug -> current work-around additional pb-* */}
        <div
          className={`relative flex flex-col flex-grow p-8 gap-4 pb-[20rem] text-white scrollBackground`}
        >
          <video
            autoPlay
            muted
            loop
            className="absolute top-0 right-0 bottom-0 left-0 w-auto min-w-full min-h-full max-w-none h-auto object-cover z-0 opacity-25"
            onError={undefined}
          >
            <source src="/video/WarpedBGWeb.mp4" type="video/mp4" />
          </video>
          <div className={`text-2xl sm:text-4xl mt-14`}>{`Collected`}</div>
          {/* Collection Card Section */}
          <div className={`flex flex-grow w-full flex-row flex-wrap gap-4`}>
            <CollectionCardSection />
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
}

type ScaleFactor = 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9;

type ScaleFactors = {
  mobile: ScaleFactor;
  sm: ScaleFactor;
};

function CollectionCardSection() {
  const collectionCards = Array.from({ length: 20 }, (_, i) => ({ index: i }));

  const scaleFactor: ScaleFactors = {
    mobile: 0.5,
    sm: 0.8,
  };

  const { isMobile } = useIsMobile();

  return (
    <>
      <div className={`hidden invisible`}>
        <div className={`scale-[10%]`}></div>
        <div className={`scale-[20%]`}></div>
        <div className={`scale-[30%]`}></div>
        <div className={`scale-[40%]`}></div>
        <div className={`scale-[50%]`}></div>
        <div className={`scale-[60%]`}></div>
        <div className={`scale-[70%]`}></div>
        <div className={`scale-[80%]`}></div>
        <div className={`scale-[90%]`}></div>
      </div>
      {collectionCards.map((el) => (
        <div
          key={el.index}
          className="inline-block"
          style={{
            width: `calc(24rem * ${
              isMobile ? scaleFactor.mobile : scaleFactor.sm
            })`, // Adjust width based on scale factor
            height: `calc(36rem * ${
              isMobile ? scaleFactor.mobile : scaleFactor.sm
            })`, // Adjust height based on scale factor
          }}
        >
          <CollectionCard
            className={`scale-[${
              isMobile ? scaleFactor.mobile * 100 : scaleFactor.sm * 100
            }%] origin-top-left`}
          />
        </div>
      ))}
    </>
  );
}
