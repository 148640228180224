// Motion Capture Core - Face Landmark
// Wei-1 2023-10-03

// Fixed CDN imports causing build problems
import {
  FaceLandmarker,
  FaceLandmarkerOptions,
  FaceLandmarkerResult,
  FilesetResolver,
  ImageSource,
} from "@mediapipe/tasks-vision";

let faceLandmarker: FaceLandmarker | null = null;

/**
 * Initializes the face landmarker with the provided configuration options.
 * @param config - The configuration options for the face landmarker.
 * @returns A promise that resolves when the face landmarker is initialized.
 */
async function initFaceLandmarker(
  config: FaceLandmarkerOptions
): Promise<void> {
  const filesetResolver = await FilesetResolver.forVisionTasks("/wasm");
  faceLandmarker = await FaceLandmarker.createFromOptions(
    filesetResolver,
    config
  );
}

/**
 * Checks if the face landmarker is available.
 * @returns {boolean} Face landmarker available
 */
function checkFaceLandmarker(): boolean {
  return faceLandmarker !== null;
}

/**
 * Predicts the face landmark points in an image.
 * @param data The image source to analyze.
 * @returns The result of the face landmark prediction.
 * @throws Error if the FaceLandmarker is not initialized.
 */
function predictFaceLandmark(data: ImageSource): FaceLandmarkerResult {
  if (!faceLandmarker) {
    throw new Error("FaceLandmarker is not initialized.");
  }
  const startTimeMs = performance.now();
  return faceLandmarker.detectForVideo(data, startTimeMs);
}

export { initFaceLandmarker, checkFaceLandmarker, predictFaceLandmark };
