import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon, MoonIcon, UserIcon } from "@heroicons/react/20/solid";
import { ButtonElement } from "../dom/ContextBar/ButtonElement";
import { ToggleSwitch } from "./ToggleSwitch";

import { useNavigate } from "react-router-dom";
import { DropdownItem } from "./Dropdown/DropdownItem";

import {
  Dispatch,
  Fragment,
  SetStateAction,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";

interface ProfileButtonProps {
  classNameButton?: string;
  settingsState?: [boolean, Dispatch<SetStateAction<boolean>>];
}

const ProfileButton = forwardRef<HTMLDivElement, ProfileButtonProps>(
  ({ classNameButton = "h-14 w-auto", settingsState }, ref) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => internalRef.current!);

    const navigate = useNavigate();

    return (
      <div className="relative">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="justify-center transition-colors rounded-md text-sm font-medium text-white">
            <ButtonElement
              className={`w-full h-full hover:brightness-125 transition-all duration-300`}
              ref={internalRef}
            >
              <img
                src={"/img/profile-pic.png"}
                className={`border-2 border-primarygreen rounded-full max-h-full object-cover ${classNameButton}`}
                alt={"Profile"}
              />
            </ButtonElement>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute translate-y-[50%] top-0 left-0 mt-0 w-56 origin-top-right divide-y divide-primarygreen rounded-md bg-black/50 backdrop-blur-md shadow-lg ring-1 ring-black/5 focus:outline-none z-[30]">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <DropdownItem
                      label={`Profile`}
                      hover={active}
                      onClick={() => navigate("/profile")}
                      icon={<UserIcon className={`w-4 h-auto`}></UserIcon>}
                    ></DropdownItem>
                  )}
                </Menu.Item>
                {/* <Menu.Item>
                {({ active }) => (
                  <DropdownItem
                    label={`Select Alias`}
                    hover={active}
                    icon={<UsersIcon className={`w-4 h-auto`}></UsersIcon>}
                  ></DropdownItem>
                )}
              </Menu.Item> */}
              </div>

              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <DropdownItem
                      label={`Settings`}
                      hover={active}
                      onClick={() => {
                        settingsState && settingsState[1](!settingsState[0]);
                      }}
                      icon={
                        <Cog6ToothIcon className={`w-4 h-auto`}></Cog6ToothIcon>
                      }
                    ></DropdownItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <DropdownItem
                      hover={active}
                      icon={<MoonIcon className={`w-4 h-auto`}></MoonIcon>}
                    >
                      <ToggleSwitch
                        labelEnabled="Night Mode"
                        labelDisabled="Night Mode"
                        className={`w-full justify-between`}
                      />
                    </DropdownItem>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  }
);

export { ProfileButton };
