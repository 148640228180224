interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

const Image = ({ src, alt, className }: ImageProps): JSX.Element => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onContextMenu={(e) => e.preventDefault()}
      onDragStart={(e) => e.preventDefault()}
      onDragEnd={(e) => e.preventDefault()}
      onDrag={(e) => e.preventDefault()}
    />
  );
};

export { Image };
