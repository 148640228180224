import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import { Image } from "./Image";
import { useEffect, useState } from "react";

export function CollectionCard({ className }: { className?: string }) {
  const [cardImage, setCardImage] = useState<string>("/img/avatar1.jpg");

  // randomize image in on mount
  useEffect(() => {
    const randomImage = Math.floor(Math.random() * 2) + 1;
    setCardImage(`/img/avatar${randomImage}.jpg`);
  }, []);

  return (
    <div
      className={`flex flex-col w-[calc(24rem)] h-[calc(36rem)] rounded-3xl overflow-clip group hover:ring-4 hover:ring-primarygreen transition-all duration-300 ${className}`}
    >
      <div className={`w-full h-[60%] opacity-90 backdrop-blur-lg`}>
        <Image
          src={cardImage}
          alt={"Avatar"}
          className={`w-full h-full object-cover group-hover:brightness-110 transition-all duration-300`}
        />
      </div>
      <div className={`bg-white/10 backdrop-blur-sm flex-grow text-white p-8`}>
        <div className={`flex flex-col gap-8 h-full justify-between`}>
          <div className={`flex flex-col gap-2`}>
            <div
              className={`text-xl uppercase font-bold`}
            >{`Collection Name`}</div>
            <div className={`text-base uppercase`}>{`Description`}</div>
          </div>
          <div className={`flex flex-row justify-between`}>
            <Button className={`w-full h-full uppercase`} label="Collect" />
            <Button
              className={`w-full h-full uppercase`}
              label={<InformationCircleIcon className={`w-5 h-5`} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
