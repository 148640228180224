import { Dropdown } from "../../ui-components/Dropdown";
import { LoginSection } from "./LoginSection";
import { UISection } from "../../ui-components/UISection";
import { DialogSection } from "./DialogSection";
import { ToggleSwitch } from "../../ui-components/ToggleSwitch";
import { BackgroundTabSection } from "./BackgroundTabSection";
import { LogoSectionSm, MainSectionSm } from "../../dom/ContextBar/ContextBar";
import { CollectionCard } from "../../ui-components/CollectionCard";
import TooltipSection from "../../ui-components/TooltipSection";

function UIElements() {
  return (
    <div
      className={`w-[100%] min-h-full absolute bg-charcoal p-4 sm:p-8 text-white selection:bg-primarygreen selection:text-charcoal`}
    >
      <div className={`w-10 h-10 absolute top-0 left-0 m-8`}>
        <img src="/img/alias-logo-green.png" alt={`Alias Logo`} />
      </div>

      <div
        className={`relative w-full h-full flex flex-col mt-20 gap-12 border-red-500`}
        id="page-main-flexbox"
      >
        <LoginSection />
        <UISection
          title="Dropdown Menu"
          classNameContainer={`min-h-[25vh] p-8`}
          backgroundImage="/img/futuristic-bedroom.webp"
          mountButtonEnabled={false}
        >
          <div className={`self-start`}>
            <Dropdown />
          </div>
        </UISection>

        <UISection
          title="Profile Picture Dropdown"
          description={
            <div>{`Mocking dropdown menu for profile picture in sidebar`}</div>
          }
          mountButtonEnabled={false}
          className={``}
          classNameContainer="min-h-[100vh] w-[25vw]"
          backgroundImage="/img/futuristic-bedroom.webp"
        >
          <div className={`block self-start`}>
            <div
              className={`absolute w-20 h-[100vh] bg-charcoal flex flex-col p-5 py-10 z-50`}
            >
              <LogoSectionSm />
              <MainSectionSm />
            </div>
          </div>
        </UISection>

        <DialogSection />

        <UISection title="Toggle Switches" mountButtonEnabled={false}>
          <div
            className={`self-start flex flex-col gap-4 w-full sm:w-1/2 md:w-1/3 justify-between`}
          >
            <ToggleSwitch
              labelEnabled="Some setting enabled"
              labelDisabled="Some setting disabled"
              justify
            />
            <ToggleSwitch
              labelEnabled="Another setting enabled"
              labelDisabled="Another setting disabled"
              justify
            />
          </div>
        </UISection>

        <BackgroundTabSection />

        <UISection title="Collection Card" classNameContainer={`min-h-[25vh]`}>
          <CollectionCard />
        </UISection>

        <TooltipSection />
      </div>
    </div>
  );
}

export { UIElements };
