import React, { createContext, useContext, useState, useCallback } from "react";
import ToolTip, { ToolTipProps, toolTipVisible } from "./ToolTip";

interface ToolTipContextType {
  showToolTip: (props: ToolTipProps) => void;
}

const ToolTipContext = createContext<ToolTipContextType>({
  showToolTip: () => {},
});

export const ToolTipContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [props, setProps] = useState<ToolTipProps>();

  const showToolTip = useCallback(
    ({ title, content, refElement, buttons, options }: ToolTipProps) => {
      setProps({
        title,
        content,
        buttons,
        refElement,
        options,
      });
      toolTipVisible.value = true;
    },
    []
  );

  return (
    <ToolTipContext.Provider value={{ showToolTip }}>
      {children}
      {props && <ToolTip {...props} />}
    </ToolTipContext.Provider>
  );
};

export const useToolTip = () => useContext(ToolTipContext);
