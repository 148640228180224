/* eslint-disable @typescript-eslint/no-unused-vars */
import { Canvas, useThree } from "@react-three/fiber";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import BackgroundImageCanvas from "./BackgroundImageCanvas";
import { BackgroundProps } from "../background/Background";
import { Backgound360 } from "./Background360";
import { OrbitControls } from "@react-three/drei";
import { BackgroundChromaKey } from "./BackgroundChromaKey";
import { customColorSignal } from "../ui-components/TabMenuBackground";

interface MainCanvasProps {
  children: React.ReactNode;
  currentBackground: BackgroundProps;
}

/**
 * MainCanvas component represents the main canvas for rendering 3D scenes.
 *
 * @param {React.Ref<HTMLCanvasElement>} ref - The ref to access the internal canvas element.
 * @param {MainCanvasProps} props - The props for the MainCanvas component.
 * @returns {JSX.Element} The rendered MainCanvas component.
 */
const MainCanvas = forwardRef<HTMLCanvasElement, MainCanvasProps>(
  ({ children, currentBackground }, ref) => {
    const internalCanvasRef = useRef<HTMLCanvasElement | null>(null);

    useImperativeHandle(ref, () => internalCanvasRef.current!);

    return (
      <Canvas
        ref={internalCanvasRef}
        onCreated={({ gl, scene, camera }) => {
          console.log(`Canvas created:`);
          console.log(gl.domElement);
        }}
        className={``}
      >
        <directionalLight intensity={3} position={[-1, 0, 2]} />

        {currentBackground.type === "Static Image" && (
          <BackgroundImageCanvas imageUrl={currentBackground.url} />
        )}

        {currentBackground.type === "360 Image" && (
          <Backgound360 currentBackground={currentBackground} />
        )}

        {currentBackground.type === "Chroma Key" &&
          (currentBackground.title === "Custom" ? (
            <BackgroundChromaKey color={customColorSignal.value || "#f00"} />
          ) : (
            <BackgroundChromaKey color={currentBackground.color || "#0f0"} />
          ))}

        <ambientLight />
        {children}
      </Canvas>
    );
  }
);

export default MainCanvas;
