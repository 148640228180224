/* eslint-disable @typescript-eslint/no-unused-vars */
import { UsersIcon } from "@heroicons/react/24/solid";
import {
  BsCameraVideoFill,
  BsCameraVideoOffFill,
  BsDownload,
  BsImage,
} from "react-icons/bs";
import { ButtonElement } from "../ContextBar/ButtonElement";

import { useContext, useEffect, useRef, useState } from "react";
import { ButtonStateContext } from "../../dom/ContextBar/ButtonElement";
import { useRecordingContext } from "../../pages/RecordMode";
import { IVrmProps } from "../../vrm/vrm";
import { SelectVRMWindow } from "../SelectVrmWindow";

export function SideBar({
  bgState,
  recordingUrl,
  selectVrmButtonState,
  selectedVrm,
  setSelectedVrm,
}: {
  bgState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  recordingUrl?: string | null;
  selectVrmButtonState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  selectedVrm?: IVrmProps;
  setSelectedVrm?: React.Dispatch<React.SetStateAction<IVrmProps>>;
}) {
  const selectVrmButtonRef = useRef<HTMLDivElement | null>(null);
  const [selectVrmWindowPos, setselectVrmWindowPos] = useState({
    top: 0,
    left: 0,
  });

  const { toggleWebCam, webCamRunning } = useRecordingContext();

  const handleDownloadClick = () => {
    if (recordingUrl) {
      const a = document.createElement("a");
      a.href = recordingUrl;
      a.download = `Alias-Recording-${new Date().toLocaleString()}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  // calculate position of select vrm window
  useEffect(() => {
    if (!selectVrmButtonState) return;

    if (selectVrmButtonState[0] && selectVrmButtonRef.current) {
      const rect = selectVrmButtonRef.current.getBoundingClientRect();
      setselectVrmWindowPos({
        top: rect.top + window.scrollY,
        left: rect.right + window.scrollX + rect.width / 2,
      });
    }
  }, [selectVrmButtonState]);

  return (
    <>
      <div
        className={`w-full h-full bottom-0 fixed flex flex-col justify-center items-start p-8 pointer-events-none`}
      >
        <div
          className={`w-6 h-auto max-w-full flex flex-col justify-center pointer-events-auto items-center gap-10`}
        >
          <div
            className={`w-full h-auto cursor-pointer`}
            onClick={toggleWebCam}
          >
            {webCamRunning ? (
              <BsCameraVideoFill
                className={`text-primarygreen w-full h-auto `}
              />
            ) : (
              <BsCameraVideoOffFill
                className={`text-white w-full h-auto hover:text-primarygreen transition-colors duration-300`}
              />
            )}
          </div>
          <ButtonElement className={`w-full h-auto`} buttonState={bgState}>
            <ImageIcon className={`w-full h-auto`} />
          </ButtonElement>
          <ButtonElement
            buttonState={selectVrmButtonState}
            ref={selectVrmButtonRef}
            className={`w-full h-auto`}
          >
            <UsersIcon
              className={`hover:text-primarygreen transition-colors duration-300 text-white cursor-pointer`}
              // onClick={() => showDialog("Test", "info", "Test")}
            />
          </ButtonElement>
          {recordingUrl && (
            <BsDownload
              className={`text-white  w-full h-auto cursor-pointer hover:text-primarygreen transition-colors duration-300`}
              onClick={handleDownloadClick}
            />
          )}
        </div>
      </div>

      <SelectVRMWindow
        selectedVrm={selectedVrm}
        setSelectedVrm={setSelectedVrm}
        selectVrmButtonState={selectVrmButtonState}
      />
    </>
  );
}

export function ImageIcon({ className }: { className?: string }) {
  const state = useContext(ButtonStateContext);

  return (
    <BsImage
      className={`${
        state ? "text-primarygreen" : "text-white"
      } cursor-pointer hover:text-primarygreen transition-colors duration-300 ${
        className && className
      }`}
    />
  );
}
