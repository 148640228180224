export default function IconLogout() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19.9999L20 15.9999M20 15.9999L16 11.9999M20 15.9999H5.33331M12 9.6647V9.60018C12 8.1067 12 7.35941 12.2906 6.78898C12.5463 6.28722 12.9539 5.87956 13.4557 5.6239C14.0261 5.33325 14.7734 5.33325 16.2669 5.33325H22.4002C23.8937 5.33325 24.6394 5.33325 25.2098 5.6239C25.7116 5.87956 26.1206 6.28722 26.3763 6.78898C26.6666 7.35885 26.6666 8.10524 26.6666 9.5958V22.4047C26.6666 23.8952 26.6666 24.6406 26.3763 25.2104C26.1206 25.7122 25.7116 26.1206 25.2098 26.3762C24.64 26.6666 23.8947 26.6666 22.4041 26.6666H16.2625C14.772 26.6666 14.0256 26.6666 13.4557 26.3762C12.9539 26.1206 12.5463 25.7118 12.2906 25.21C12 24.6396 12 23.8934 12 22.3999V22.3333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
