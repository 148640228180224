import { Image } from "../../ui-components/Image";
import { ProfileButton } from "../../ui-components/ProfileButton";
import { Dispatch, SetStateAction } from "react";
import { signal } from "@preact/signals-react";

export const profileButtonSignal = signal<HTMLDivElement | null>(null);

export default function TopBar({
  settingsState,
}: {
  settingsState?: [boolean, Dispatch<SetStateAction<boolean>>];
}) {
  return (
    <>
      <div
        className={`fixed w-[100vw] h-14 flex flex-row justify-between px-4 sm:px-8 mt-4 sm:mt-8`}
      >
        <div className={`h-full w-auto`}>
          <ProfileButton
            classNameButton={`h-14 w-auto`}
            settingsState={settingsState}
            ref={(ref) => (profileButtonSignal.value = ref)}
          />
        </div>
        <div className={`h-full w-auto flex flex-col justify-end`}>
          <Image
            src={`/img/alias_primarygreen_alpha.png`}
            alt={`Alias Logo`}
            className={`h-10 w-auto object-cover`}
          />
        </div>
      </div>
    </>
  );
}
