/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoginButton } from "../../ui-components/LoginButton";
import { MountStateContext, UISection } from "../../ui-components/UISection";
import { Image } from "../../ui-components/Image";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect } from "react";

function LoginSectionElements() {
  const mountState = useContext(MountStateContext);

  if (mountState === undefined) {
    throw new Error("LoginSection must be used within a UISection.");
  }

  useEffect(() => {
    // console.log(mountState);
  }, [mountState]);

  const divInitialOffset = "-5rem";
  const animateInDuration = 0.5;
  const animateInEase = "easeOut";

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          y: "0rem",
          transition: {
            duration: animateInDuration,
            delay: 0.0,
            ease: animateInEase,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: animateInDuration,
            delay: 0.0,
            ease: animateInEase,
          },
        }}
        className="w-full sm:w-1/2 h-auto bg-primarygreen items-center justify-between gap-8 p-8 sm:p-12 rounded-3xl flex flex-col  text-black font-purista "
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: animateInDuration,
              delay: 0.1,
              ease: animateInEase,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: animateInDuration,
              delay: 0.1,
              ease: animateInEase,
            },
          }}
          className={`text-2xl`}
        >{`Sign in to get started`}</motion.div>
        <LoginButton animateInDuration={animateInDuration} />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: animateInDuration,
              delay: 0.3,
              ease: animateInEase,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: animateInDuration,
              delay: 0.3,
              ease: animateInEase,
            },
          }}
          className={`flex flex-row items-baseline gap-4`}
        >
          <div>{`Powered by`}</div>
          <div className={`w-24 h-auto`}>
            <Image
              src="/img/alias_black_alpha.png"
              className={`object-cover w-full h-full relative top-[2px] `}
              alt="Alias Logo"
            />
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}

function LoginSection() {
  return (
    <UISection title="Login Section">
      <LoginSectionElements />
    </UISection>
  );
}

export { LoginSection };
