/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { toolTipVisible } from "../../ui-components/ToolTip/ToolTip";
import { useToolTip } from "../../ui-components/ToolTip/ToolTipContextProvider";
import {
  bottomBarSignal,
  sparkleButtonSignal,
  cameraButtonSignal,
} from "../BottomBar/BottomBar";
import { profileButtonSignal } from "../TopBar/TopBar";
import useLocalStorage from "../../utility/useLocalStorage";

export interface ITutorialState {
  tutStarted: boolean;
  tutCompleted: boolean;
  tutStep: number;
}

/**
 * Custom hook for managing the tutorial state and displaying tooltips.
 * The tutorial guides the user through the features of the application.
 * @returns An object containing the tutorial state.
 */
export function useTutorial() {
  const { showToolTip } = useToolTip();

  const [tutorialState, setTutorialState] = useLocalStorage<ITutorialState>(
    "tutorialState",
    {
      tutStarted: false,
      tutCompleted: false,
      tutStep: 0,
    }
  );

  useEffect(() => {
    if (!tutorialState.tutCompleted && !tutorialState.tutStarted) {
      showToolTip({
        title: "Welcome to Alias!",
        content: (
          <>
            <p>
              {`We're thrilled to have you on board and ready to start creating with
              ease. If you're new here, we'd love to guide you through a quick
              on-screen tutorial to get you familiar with all the cool features
              Alias has to offer.`}
            </p>
            <p>{`It'll only take a few minutes!`}</p>
          </>
        ),
        buttons: [
          {
            label: "Skip",
            onClick: () => {
              setTutorialState({ ...tutorialState, tutCompleted: true });
            },
          },
          {
            label: "Show Tutorial",
            onClick: () => {
              setTutorialState({ ...tutorialState, tutStarted: true });
            },
          },
        ],
        refElement: null,
        options: {
          placement: "top",
          clickBgClose: false,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (tutorialState.tutStarted && !tutorialState.tutCompleted) {
      if (tutorialState.tutStep === 0) {
        showToolTip({
          title: "Bottom Bar",
          content: (
            <>
              <p>{`Welcome to your control center! The bottom bar is where the magic happens. Let’s take a quick look at its key features.!`}</p>
            </>
          ),
          buttons: [
            {
              label: "Next",
              onClick: () => {
                setTutorialState({
                  ...tutorialState,
                  tutStep: tutorialState.tutStep + 1,
                });
              },
            },
          ],
          refElement: bottomBarSignal.value,
          options: {
            placement: "top",
            clickBgClose: false,
          },
        });
        toolTipVisible.value = true;
      }

      if (tutorialState.tutStep === 1) {
        showToolTip({
          title: "Selecting Background and Avatar:",
          content: (
            <>
              <p>{`Tap on the dropdown menu here to select a background and an avatar. Mix and match to find the perfect combination for your story.`}</p>
            </>
          ),
          buttons: [
            {
              label: "Next",
              onClick: () => {
                setTutorialState({
                  ...tutorialState,
                  tutStep: tutorialState.tutStep + 1,
                });
              },
            },
          ],
          refElement: sparkleButtonSignal.value,
          options: {
            placement: "top",
            clickBgClose: false,
          },
        });
        toolTipVisible.value = true;
      }

      if (tutorialState.tutStep === 2) {
        showToolTip({
          title: "Enable Camera",
          content: (
            <>
              <p>{`Before you start recording, let’s get your camera ready. This button here toggles your camera on and off. Remember to allow camera access to let your avatar mirror your movements.`}</p>
            </>
          ),
          buttons: [
            {
              label: "Next",
              onClick: () => {
                setTutorialState({
                  ...tutorialState,
                  tutStep: tutorialState.tutStep + 1,
                });
              },
            },
          ],
          refElement: cameraButtonSignal.value,
          options: {
            placement: "top",
            clickBgClose: false,
          },
        });
        toolTipVisible.value = true;
      }

      if (tutorialState.tutStep === 3) {
        showToolTip({
          title: "Profile & Settings",
          content: (
            <>
              <p>{`Upload a profile picture and a banner to express your unique style.`}</p>
              <p>{`Access general settings.`}</p>
            </>
          ),
          buttons: [
            {
              label: "Finish.",
              onClick: () => {
                setTutorialState({
                  ...tutorialState,
                  tutCompleted: true,
                });
              },
            },
          ],
          refElement: profileButtonSignal.value,
          options: {
            placement: "bottom",
            clickBgClose: false,
          },
        });
        toolTipVisible.value = true;
      }
    }
  }, [tutorialState]);

  return { tutorialState };
}
