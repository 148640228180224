/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ITutorialState } from "./useTutorial";
import { useToolTip } from "../../ui-components/ToolTip/ToolTipContextProvider";
import { idealVideoWidthSignal } from "../../webcam/useWebCam";
import { useDialog } from "../../ui-components/Dialog/DialogContextProvider";
import useLocalStorage from "../../utility/useLocalStorage";
import { VRM } from "@pixiv/three-vrm";

/**
 * Custom hook for setting up permissions and tutorial.
 *
 * @param tutorialState - The state of the tutorial.
 * @param webCamRunningRef - A mutable ref object indicating if the webcam is running.
 * @param toggleWebCam - A function to toggle the webcam.
 * @param currentVRM - The current VRM object.
 */
export function useSetup({
  tutorialState,
  webCamRunningRef,
  toggleWebCam,
  currentVRM,
}: {
  tutorialState: ITutorialState;
  webCamRunningRef: React.MutableRefObject<boolean>;
  toggleWebCam: () => void;
  currentVRM: VRM | null;
}) {
  const { showToolTip } = useToolTip();
  const { showDialog } = useDialog();

  const [setupShown, setSetupShown] = useLocalStorage<boolean>(
    "setupShown",
    false
  );

  const setupRecording = () => {
    var constraints = {
      video: {
        width: { ideal: idealVideoWidthSignal.value },
      },
      audio: true,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        // Permissions have been granted
        stream.getTracks().forEach((track) => track.stop());

        if (!webCamRunningRef.current) {
          toggleWebCam();
        }
      })
      .catch((error) => {
        // Handle errors such as user denying permissions or no devices found
        if (
          error.name === "NotAllowedError" ||
          error.name === "NotFoundError"
        ) {
          showDialog({
            title: "Camera/Microphone Permission",
            type: "error",
            content: `Access to camera and/or microphone was denied or not available. Please ensure your device has a camera/microphone and grant permission.`,
          });
        } else {
          showDialog({
            title: "Error",
            type: "error",
            content: `An unexpected error occurred: ${error.message}`,
          });
        }
        console.error(error);
      });
  };

  useEffect(() => {
    if (tutorialState.tutCompleted && currentVRM) {
      if (!setupShown) {
        showToolTip({
          title: "Setup Required",
          content: (
            <>
              <p>{`We need to set up your device permissions for recording! Please approve the following camera and microphone permissions to ensure a smooth experience.`}</p>
            </>
          ),
          buttons: [
            {
              label: "OK",
              onClick: setupRecording,
            },
          ],
          refElement: null,
          options: {
            placement: "top",
            clickBgClose: false,
          },
        });

        setSetupShown(true);
      } else {
        setupRecording();
      }
    }
  }, [tutorialState, currentVRM]);
}
