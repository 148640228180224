// Motion Capture Core - Body Position

import {
  FaceLandmarkerResult,
  NormalizedLandmark,
} from "@mediapipe/tasks-vision";
import { IBodyPosition } from "../types/motion-capture";

let noseKey = 94;

function getNoseXYZ(nose: NormalizedLandmark): NormalizedLandmark {
  return { x: nose.x - 0.5, y: nose.y - 0.5, z: nose.z };
}

/**
 * Retrieves the body position based on the given FaceLandmarkerResult.
 * @param result The FaceLandmarkerResult containing the face landmarks.
 * @returns An array of IBodyPosition objects representing the body position.
 */
function getBodyPosition(result: FaceLandmarkerResult): IBodyPosition[] {
  if (result.faceLandmarks.length === 0) {
    return [];
  }
  let chin = result.faceLandmarks[0][noseKey];
  let noseXYZ = getNoseXYZ(chin);

  // Wrap the chin coordinates in an object with a 'position' property
  return [{ position: noseXYZ }];
}

export { getBodyPosition };
