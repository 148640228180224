import { Button } from "../../ui-components/Button";
import { DialogComponent } from "../../ui-components/Dialog/DialogComponent";
import { UISection } from "../../ui-components/UISection";
import { useState, useMemo } from "react";
import { loremIpsum } from "lorem-ipsum";

export function DialogSection() {
  const [infoOpened, setInfoOpened] = useState(false);
  const [warningOpened, setWarningOpened] = useState(false);

  const contentInfo = useMemo(() => {
    return loremIpsum({ count: 1, units: "paragraphs" });
  }, []);

  const contentWarning = useMemo(() => {
    return loremIpsum({ count: 1, units: "paragraphs" });
  }, []);

  return (
    <UISection title="Modals" mountButtonEnabled={false}>
      <div className={`self-start flex flex-col gap-4`}>
        <Button
          label="Open Info"
          onClick={() => setInfoOpened(true)}
          className={`self-start justify-self-start`}
        />
        <DialogComponent
          isOpen={infoOpened}
          onClose={() => setInfoOpened(false)}
          title="Notification"
          content={contentInfo}
        />
        <Button
          label="Open Warning"
          onClick={() => setWarningOpened(true)}
          className={`self-start justify-self-start`}
        />
        <DialogComponent
          isOpen={warningOpened}
          type="warning"
          onClose={() => setWarningOpened(false)}
          title="Warning"
          content={contentWarning}
        />
      </div>
    </UISection>
  );
}
