import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import RecordMode from "./pages/RecordMode";
import { UIElements } from "./pages/UIElements/UIElements";
import { Profile } from "./pages/Profile";
// import Login from "./pages/Login";

/**
 * Initializes react router.
 * */
const router = createBrowserRouter([
  {
    path: "/",
    element: <RecordMode />,
  },
  {
    path: "ui-elements",
    element: <UIElements />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
