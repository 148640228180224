import { Popover, Transition } from "@headlessui/react";
// import { loremIpsum } from "lorem-ipsum";
import { Fragment, useState } from "react";
import { usePopper } from "react-popper";
import { Button } from "../Button";
import { effect, signal } from "@preact/signals-react";
import { Placement, VirtualElement } from "@popperjs/core";

interface ToolTipButton {
  label: string;
  onClick: () => void;
}

interface ToolTipOptions {
  placement?: Placement;
  clickBgClose?: boolean;
}

export interface ToolTipProps {
  title: string;
  content: string | React.ReactNode;
  buttons?: ToolTipButton[];
  refElement?: HTMLElement | null;
  options?: ToolTipOptions;
}

export const toolTipVisible = signal(false);

const virtualElement: VirtualElement = {
  getBoundingClientRect: () => ({
    width: 0,
    height: 0,
    top: window.innerHeight / 2 + 100,
    right: window.innerWidth / 2,
    bottom: window.innerHeight / 2,
    left: window.innerWidth / 2,
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
    toJSON: () => {}, // Dummy implementation, as this method is required by the DOMRect interface
  }),
};

export default function ToolTip({
  title,
  content,
  buttons = [{ label: "Next", onClick: () => {} }],
  refElement,
  options,
}: ToolTipProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(
    refElement ?? virtualElement,
    popperElement,
    {
      placement: options?.placement || "bottom",
    }
  );

  effect(() => {
    if (toolTipVisible.value) {
      refElement?.classList.add("border", "border-primarygreen");
    } else {
      refElement?.classList.remove("border", "border-primarygreen");
    }
  });

  const handleClose = () => {
    toolTipVisible.value = false;
  };

  return (
    <Popover className={`font-purista`}>
      <>
        {toolTipVisible.value && (
          <>
            <Transition appear show={toolTipVisible.value} as={"div"}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay
                  className="fixed inset-0 bg-black opacity-30"
                  onClick={() => (options?.clickBgClose ? handleClose() : {})}
                  static
                />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className={`max-w-[90%] sm:max-w-[33%]`}
                  static
                >
                  <div
                    className="flex flex-col gap-10 m-4 rounded-2xl bg-white/5 text-white backdrop-blur-lg p-6 text-left align-middle  selection:bg-primarygreen selection:text-charcoal shadow-xl"
                    id={`ToolTip-${title}`}
                  >
                    <div className={`text-xl`}>{`${title}`}</div>
                    <div>{content}</div>
                    <div
                      className={`flex flex-row w-full ${
                        buttons.length > 1
                          ? "justify-between"
                          : "justify-center"
                      } gap-4`}
                    >
                      {/* <div
                        className={`flex flex-col justify-center text-lg select-none`}
                      >{`1 / 3`}</div> */}
                      {buttons.map((button, idx) => (
                        <Button
                          key={idx}
                          label={button.label}
                          onClick={() => {
                            button.onClick();
                            handleClose();
                          }}
                          className="text-xs sm:text-sm"
                        />
                      ))}
                      {/* <Button label={`Close`} onClick={handleClose} /> */}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </Transition>
          </>
        )}
      </>
    </Popover>
  );
}
