/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import { useRecordingContext } from "../../pages/RecordMode";
import {
  BsRecordCircle,
  BsPlayCircle,
  BsStopCircle,
  BsCameraVideo,
} from "react-icons/bs";
import { useDialog } from "../../ui-components/Dialog/DialogContextProvider";
import { useLocation } from "react-router-dom";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

/**
 * Represents the record button component.
 *
 * @component
 * @param {React.Ref<HTMLDivElement>} ref - The ref object for the component.
 * @returns {JSX.Element} The JSX element representing the record button.
 */
const RecordButton = forwardRef<HTMLDivElement>(({}, ref) => {
  const internalRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(ref, () => internalRef.current!);

  const { showDialog } = useDialog();
  const { recordingState, setRecordingState, webCamRunning } =
    useRecordingContext();

  const location = useLocation();

  const [clock, setClock] = useState({
    startTime: new Date(),
    isRunning: false,
  });

  let timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const maxLength = 30;

  useEffect(() => {
    if (recordingState === "stopped" && clock.isRunning) {
      const endTime = new Date();
      const elapsedSeconds =
        (endTime.getTime() - clock.startTime.getTime()) / 1000;
      console.log("Elapsed Time:", elapsedSeconds, "seconds");

      if (elapsedSeconds >= maxLength) {
        showDialog({
          title: "Recording stopped",
          type: "info",
          content: `Recording automatically stopped after ${maxLength} seconds.`,
        });
      }

      setClock({ ...clock, isRunning: false });
    }
  }, [recordingState, clock]);

  const handleClick = () => {
    if (location.pathname !== "/") return;

    switch (recordingState) {
      case "recording-ml":
        setRecordingState("stopped");

        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
          timerIdRef.current = null;
        }

        break;
      case "stopped":
        setRecordingState("viewing");
        break;
      case "viewing":
        setRecordingState("stopped");
        break;
      case "initializing":
        if (!webCamRunning) {
          showDialog({
            title: "Webcam inactive",
            type: "info",
            content: `Please enable your webcam from the sidebar before trying to record a video.`,
          });
        } else {
          setRecordingState("recording-ml");
          setClock({ startTime: new Date(), isRunning: true }); // Start the clock
          timerIdRef.current = setTimeout(() => {
            setRecordingState("stopped");
          }, maxLength * 1000);
        }

        break;
      default:
        break;
    }
  };

  // Function to get the appropriate icon based on the recording state
  const getIcon = () => {
    switch (recordingState) {
      case "initializing":
        return (
          <BsRecordCircle
            className={`${
              webCamRunning
                ? "text-white hover:text-primarygreen"
                : "text-white/50"
            } w-auto h-full transition-colors duration-300 `}
          />
        );
      case "recording-ml":
        return (
          <BsStopCircle className="text-[#ff2121] w-auto h-full hover:text-primarygreen transition-colors duration-300 " />
        );
      case "stopped":
        return (
          <BsPlayCircle className="text-white w-auto h-full hover:text-primarygreen transition-colors duration-300" />
        );
      case "viewing":
        return (
          <BsStopCircle className="text-white w-auto h-full hover:text-primarygreen transition-colors duration-300" />
        );
      default:
        return (
          <BsCameraVideo className="text-white w-auto h-full hover:text-primarygreen transition-colors duration-300" />
        );
    }
  };

  return (
    <div
      className="h-auto w-full flex flex-row justify-center cursor-pointer"
      onClick={handleClick}
    >
      {getIcon()}
    </div>
  );
});

export { RecordButton };
