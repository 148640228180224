import * as THREE from "three";

interface IVrmProps {
  name: string;
  url: string;
  offset: THREE.Vector3;
  rotation: THREE.Vector3;
  blendshapesUpperCase: boolean;
}

/**
 * Array of VRM properties.
 * @typedef {Object} IVrmProps
 * @property {string} name - The name of the VRM.
 * @property {string} url - The URL of the VRM file.
 * @property {THREE.Vector3} offset - The offset position of the VRM.
 * @property {THREE.Vector3} rotation - The rotation of the VRM.
 * @property {boolean} blendshapesUpperCase - Indicates whether the blendshapes should be in uppercase.
 */
const vrms: IVrmProps[] = [
  {
    name: "Ekko",
    url: "https://aliastemp.nyc3.digitaloceanspaces.com/Ekko_v4.vrm",
    offset: new THREE.Vector3(0, 0, 0),
    rotation: new THREE.Vector3(0, 0, 0),
    blendshapesUpperCase: true,
  },
  {
    name: "Lucy",
    url: "https://aliastemp.nyc3.digitaloceanspaces.com/Lucy_byKitzoomer.vrm",
    offset: new THREE.Vector3(0, -0.175, 0),
    rotation: new THREE.Vector3(0, 0, 0),
    blendshapesUpperCase: true,
  },
  {
    name: "Vroid Male",
    url: "https://aliastemp.nyc3.digitaloceanspaces.com/VRoid_V110_Male_v1.1.3_52BlendShape.vrm",
    offset: new THREE.Vector3(0, -0.125, 0),
    rotation: new THREE.Vector3(0, 0, 0),
    blendshapesUpperCase: true,
  },
  {
    name: "Tini",
    url: "https://aliastemp.nyc3.cdn.digitaloceanspaces.com/Tini.vrm",
    offset: new THREE.Vector3(0, -0.3, 0),
    rotation: new THREE.Vector3(0, 0, 0),
    blendshapesUpperCase: false,
  },
  // {
  //   name: "VRoid A",
  //   url: "https://aliastemp.nyc3.cdn.digitaloceanspaces.com/AvatarSample_A.vrm",
  //   offset: new THREE.Vector3(0, -0.1, 0),
  //   rotation: new THREE.Vector3(0, 0, 0),
  //   blendshapesUpperCase: true,
  // },
];

export { vrms, type IVrmProps };
