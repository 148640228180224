import { motion } from "framer-motion";
import { AiOutlineGoogle } from "react-icons/ai";
import { useIsMobile } from "../utility/useIsMobile";
import { useEffect } from "react";

function LoginButton({
  animateInDuration = 0.5,
  animateInEase = "easeOut",
}: {
  animateInDuration?: number;
  animateInEase?: string;
}) {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    // console.log(isMobile);
  }, [isMobile]);

  return (
    <motion.div
      className={`bg-black relative  w-full h-16 flex flex-row text-primarygreen border sm:hover:text-black rounded-lg overflow-clip duration-300 border-black select-none  transition-colors items-center gap-4 p-4 cursor-pointer`}
      onClick={() => console.log(`Login button clicked.`)}
      whileHover="hover"
      whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
      onContextMenu={(e) => e.preventDefault()}
      onDragStart={(e) => e.preventDefault()}
      onDragEnd={(e) => e.preventDefault()}
      onDrag={(e) => e.preventDefault()}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: animateInDuration,
          delay: 0.2,
          ease: animateInEase,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: animateInDuration,
          delay: 0.2,
          ease: animateInEase,
        },
      }}
    >
      {!isMobile && (
        <>
          {/** Scroll effect on hover */}
          <motion.div
            className={`absolute top-0 left-0 h-full bg-primarygreen z-10`}
            initial={{ width: "0%" }}
            animate={{ width: "0%" }}
            variants={{
              hover: { width: "100%" },
            }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          ></motion.div>
        </>
      )}

      <motion.div className={`w-12 h-auto z-20`}>
        <AiOutlineGoogle className={`w-full h-full`} />
      </motion.div>
      <div
        className={`absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center z-20`}
      >
        <motion.div className={`text-xl font-thin`}>{`Google`}</motion.div>
      </div>
    </motion.div>
  );
}

export { LoginButton };
