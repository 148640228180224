import { useState } from "react";
import { UISection } from "./UISection";
import { useToolTip } from "./ToolTip/ToolTipContextProvider";

export default function ToolTipSection() {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);

  const { showToolTip } = useToolTip();

  return (
    <UISection title="Tool-tip" mountButtonEnabled={false}>
      <div
        // label="Open Tool-tip"
        className={`self-start justify-self-start`}
        onClick={() => {
          showToolTip({
            title: "Test",
            content: "Test",
            refElement,
          });
        }}
        ref={setRefElement}
      >
        Test
      </div>
      {/* <ToolTip refElement={refElement} openState={openState} /> */}
    </UISection>
  );
}
